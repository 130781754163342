export const carrierKeywords = {
    "verizon": "@vtext.com",
    "vzw": "@vtext.com", // Common abbreviation for Verizon Wireless
    "windstream": "@vtext.com",
    "frontier": "@vtext.com",
    "page": "@vtext.com",
    "greatcall": "@vtext.com",
    "straight talk": "@vtext.com",
    "total wireless": "@vtext.com",
    "unreal mobile": "@vtext.com",
    "xfinity": "@vtext.com", // Xfinity Mobile often abbreviated
    "spectrum": "@vtext.com", // Spectrum Mobile
    "tracfone": "@vtext.com",
    "att": "@txt.att.net",
    "at&t": "@txt.att.net",
    "cingular": "@txt.att.net", // Older AT&T branding
    "new cingular": "@txt.att.net",
    "cellular corp": "@txt.att.net",
    "consumer cellular": "@txt.att.net",
    "airvoice": "@txt.att.net", // AirVoice Wireless
    "black wireless": "@txt.att.net",
    "h2o": "@mms.att.net", // H2O Wireless
    "cricket": "@mms.cricketwireless.net", // Cricket Wireless
    "net10": "@mms.att.net", // NET10 Wireless
    "freeup": "@txt.att.net", // FreeUP Mobile
    "t-mobile": "@tmomail.net",
    "tmobile": "@tmomail.net",
    "powertel": "@tmomail.net",
    "metro": "@mymetropcs.com", // Metro by T-Mobile
    "metropcs": "@mymetropcs.com",
    "mint": "@tmomail.net", // Mint Mobile
    "ultra": "@tmomail.net", // Ultra Mobile
    "simple": "@smtext.com", // Simple Mobile
    "boost": "@sms.myboostmobile.com", 
    "lycamobile": "@mailmymobile.net",
    "ting": "@messaging.sprintpcs.com", // Ting, often uses Sprint's network
    "solavei": "@tmomail.net", // Solavei, MVNO on T-Mobile
    "us mobile": "@tmomail.net", // US Mobile, typically uses T-Mobile's network
    "sprint": "@messaging.sprintpcs.com",
    "credo": "@messaging.sprintpcs.com",
    "hawaiian telcom": "@messaging.sprintpcs.com",
    "assurance wireless": "@vmobl.com", // Assurance Wireless, MVNO on Virgin/Sprint
    "virgin": "@vmobl.com", // Virgin Mobile
    "southern linc": "@page.southernlinc.com", // SouthernLINC
    "centurylink": "@messaging.centurytel.net",
    "telcove": "@messaging.centurytel.net",
    "level 3": "@messaging.centurytel.net",
    "pacific bell": "@pacbellpcs.net",
    "alaska communications": "@msg.acsalaska.com",
    "bell mobility": "@txt.bell.ca",
    "c spire": "@cspire1.com",
    "cincinnati bell": "@gocbw.com",
    "element mobile": "@sms.elementmobile.net",
    "gci": "@msg.gci.net", // General Communications Inc.
    "kore wireless": "@koremessaging.com",
    "zip sim": "@smtp.zip.sim",
    "project fi": "@msg.fi.google.com", // Google Fi
    "google fi": "@msg.fi.google.com", // Google Fi
    "hello mobile": "@vmobl.com", // Hello Mobile, often uses Virgin/Sprint
    "altice mobile": "@messaging.sprintpcs.com", // Altice Mobile
    "republic wireless": "@messaging.sprintpcs.com", // Republic Wireless
    "wing": "@messaging.sprintpcs.com", // Wing uses various networks including Sprint
    "pure talkusa": "@txt.att.net", // Pure TalkUSA, uses AT&T's network
    "red pocket": "@txt.att.net" // Red Pocket Mobile, uses multiple networks including AT&T
};

export const convertToCarrierEmail = (number, carrier) => {
    carrier = carrier.toLowerCase();
    


    // First, check exact matches with switch-case

    switch (carrier) {
        // Cases for specific carriers
        case 'verizon':
        case 'cellco partnership dba verizon wireless - tx':
        case 'verizon wireless 8006 - svr/2':
        case 'frontier':
        case 'greatcall':
        case 'straight talk':
        case 'total wireless':
        case 'unreal mobile':
        case 'xfinity mobile':
        case 'tracfone':
        case 'net10':
        case 'page plus':
        case 'spectrum mobile':
        case 'windstream':
            return `${number}@vtext.com`;

        case 'att':
        case 'at&t':
        case 'new cingular':
        case 'cingular':
        case 'cellular corp':
        case 'consumer cellular':
        case 'airvoice wireless':
        case 'black wireless':
        case 'cricket wireless':
        case 'freedompop':
        case 'good2go mobile':
        case 'red pocket':
        case 'pure talkusa':
        case 'freeup mobile':
            return `${number}@txt.att.net`;

        case 't-mobile':
        case 'tmobile':
        case 't-mobile us-svr-10x/2':
        case 'powertel':
        case 'mint mobile':
        case 'metropcs':
        case 'ultra mobile':
        case 'simple mobile':
        case 'lycamobile':
        case 'us mobile':
        case 'wing':
        case 'project fi':
        case 'google fi':
            return `${number}@tmomail.net`;

        case 'sprint':
        case 'credo mobile':
        case 'hawaiian telcom':
        case 'virgin mobile':
        case 'assurance wireless':
        case 'boost mobile':
        case 'freedompop':
        case 'ting':
        case 'altice mobile':
        case 'twigby':
        case 'hello mobile':
        case 'karma mobility':
        case 'republic wireless':
        case 'wing':
            return `${number}@messaging.sprintpcs.com`;

        case 'virgin mobile':
        case 'virgin':
        case 'assurance wireless':
            return `${number}@vmobl.com`;

        case 'boost mobile':
            return `${number}@sms.myboostmobile.com`;

        case 'us cellular':
        case 'uscellular':
            return `${number}@email.uscc.net`;

        case 'cricket':
        case 'cricket wireless':
            return `${number}@mms.cricketwireless.net`;

        case 'metro pcs':
        case 'metropcs':
        case 'metro':
            return `${number}@mymetropcs.com`;

        case 'google fi':
        case 'project fi':
            return `${number}@msg.fi.google.com`;

        case 'tracfone':
        case 'tracfone wireless':
            return `${number}@mmst5.tracfone.com`;

        case 'simple mobile':
            return `${number}@smtext.com`;

        case 'consumer cellular':
            return `${number}@mailmymobile.net`;

        case 'republic wireless':
            return `${number}@text.republicwireless.com`;

        case 'ting':
            return `${number}@message.ting.com`;

        case 'xfinity mobile':
            return `${number}@vtext.com`;

        case 'alltel':
            return `${number}@message.alltel.com`;

        case 'comcast':
            return `${number}@comcastpcs.textmsg.com`;

        case 'element mobile':
            return `${number}@sms.elementmobile.net`;

        case 'nextech':
        case 'nex-tech wireless':
            return `${number}@sms.ntwls.net`;

        case 'cellcom':
            return `${number}@cellcom.quiktxt.com`;

        case 'cleartalk':
            return `${number}@sms.cleartalk.us`;

        case 'cspire':
        case 'c spire':
            return `${number}@cspire1.com`;

        case 'gci':
            return `${number}@msg.gci.net`;

        case 'mobi pcs':
            return `${number}@mobipcs.net`;

        case 'viaero':
            return `${number}@viaerosms.com`;

        case 'carolina west wireless':
            return `${number}@cwwsms.com`;

        case 'chariton valley wireless':
            return `${number}@sms.cvalley.net`;

        case 'chat mobility':
            return `${number}@mail.msg.senderdirect.com`;

        case 'duo':
            return `${number}@duotelecom.com`;

        case 'epic touch':
            return `${number}@email.epicpcs.com`;

        case 'golden state cellular':
            return `${number}@gscsms.com`;

        case 'immix':
            return `${number}@sms.immix.com`;

        case 'iq cellular':
            return `${number}@sms.iqc.net`;

        case 'longlines':
            return `${number}@text.longlines.com`;

        case 'lycamobile':
            return `${number}@mailmymobile.net`;

        case 'pine cellular':
            return `${number}@text.pinecell.com`;

        case 'psc wireless':
            return `${number}@text.pscellular.com`;

        case 'simmetry':
            return `${number}@simmetrypcs.com`;

        case 'south central communications':
            return `${number}@rinasms.com`;

        case 'syringa wireless':
            return `${number}@syringawireless.com`;

        case 'united wireless':
            return `${number}@utext.com`;

        case 'west central wireless':
            return `${number}@sms.wcc.net`;

        case 'pacific bell':
            return `${number}@pacbellpcs.net`;

        case 'southern communications services':
            return `${number}@page.southernlinc.com`;

        case 'alaska communications':
            return `${number}@msg.acsalaska.com`;

        case 'bell mobility':
            return `${number}@txt.bell.ca`;

        case 'cincinnati bell':
            return `${number}@gocbw.com`;

        case 'kore wireless':
            return `${number}@koremessaging.com`;

        case 'zip sim':
            return `${number}@smtp.zip.sim`;

        case 'bluegrass cellular':
            return `${number}@mms.myblueworks.com`;

        case 'pioneer cellular':
            return `${number}@zsend.com`;

        case 'pocket wireless':
            return `${number}@sms.pocket.com`;

        // Add other specific cases as needed
        default:
            break; // Move to the keyword check if no case matched
    }

    // If no exact match, use the keyword matching
    for (let keyword in carrierKeywords) {
        if (carrier.includes(keyword)) {
            return `${number}${carrierKeywords[keyword]}`;
        }
    }

    return `${number}@unknowncarrier.com`; // Default case if no match is found
};
