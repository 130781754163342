// src/pages/Contact.js

import React, { useState } from 'react';
import Header from '../components/navs/Header';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus(null);  // Reset status
    setError(null);   // Reset error

    const telegramBotToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const telegramChatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

    const text = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: telegramChatId,
          text: text,
        }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.description || 'Failed to send message.');
      }

      setStatus('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      setError(`Error: ${error.message}. Please try again later.`);
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen text-gray-100">
      <Header />
      <main className="container mx-auto mt-8 px-4">
        <h1 className="text-4xl font-bold text-center">Contact Us</h1>
        <p className="mt-4 text-lg text-center">We'd love to hear from you! Use the form below to get in touch with us.</p>
        
        <div className="mt-8 max-w-xl mx-auto">
          <form onSubmit={handleSubmit} className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <div className="mb-4">
              <label className="block text-gray-400">Name</label>
              <input 
                type="text" 
                className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400">Email</label>
              <input 
                type="email" 
                className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400">Message</label>
              <textarea 
                className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <div className="flex justify-center">
              <button 
                type="submit" 
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
              >
                Send Message
              </button>
            </div>
            {status && <p className="mt-4 text-center text-green-500">{status}</p>}
            {error && <p className="mt-4 text-center text-red-500">{error}</p>}
          </form>
        </div>
      </main>
    </div>
  );
};

export default Contact;
