// src/components/admin/AdminAddKey.js

import React, { useState } from 'react';
import { db } from '../../config/firebaseConfig';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { pricingPlans } from '../../config/pricing';
import { motion } from 'framer-motion';

const AdminAddKey = () => {
  const [key, setKey] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(pricingPlans[0].name);
  const [duration, setDuration] = useState('1 month');
  const [message, setMessage] = useState('');

  const generateKey = () => {
    const randomKey = Math.floor(100000 + Math.random() * 900000).toString();
    setKey(randomKey);
  };

  const calculateExpiryDate = () => {
    const currentDate = new Date();
    let monthsToAdd = 1;

    if (duration === '3 months') {
      monthsToAdd = 3;
    }

    currentDate.setMonth(currentDate.getMonth() + monthsToAdd);
    return Timestamp.fromDate(currentDate);
  };

  const handleAddKey = async () => {
    if (!key) {
      setMessage('Please generate a key first.');
      return;
    }

    const selectedPlanDetails = pricingPlans.find(
      (plan) => plan.name === selectedPlan
    );

    const expiryDate = calculateExpiryDate();

    try {
      console.log('Adding document with details:', {
        key,
        category: selectedPlanDetails.name,
        expiresAt: expiryDate,
        usedBy: null,
        allocation: selectedPlanDetails.allocation,
      });

      await addDoc(collection(db, 'AccessKeys'), {
        key,
        category: selectedPlanDetails.name,
        expiresAt: expiryDate,
        usedBy: null,
        allocation: selectedPlanDetails.allocation,
      });

      setMessage(`Key ${key} added successfully!`);
      setKey('');
    } catch (error) {
      console.error('Error adding key:', error);
      setMessage(`Error adding key: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 max-w-lg">
      <motion.h2
        className="text-3xl font-bold text-gray-100 mb-6 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Add New Auth Key
      </motion.h2>
      {message && (
        <motion.p
          className="mb-4 text-green-500 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {message}
        </motion.p>
      )}
      <div className="mb-6">
        <label className="block text-gray-400 mb-2">Select Pricing Plan</label>
        <select
          className="w-full p-3 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          {pricingPlans.map((plan, index) => (
            <option key={index} value={plan.name} className="text-gray-800">
              {plan.name} ({plan.plan})
            </option>
          ))}
        </select>
      </div>
      <div className="mb-6">
        <label className="block text-gray-400 mb-2">Select Duration</label>
        <select
          className="w-full p-3 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        >
          {Object.keys(
            pricingPlans.find((plan) => plan.name === selectedPlan).prices
          ).map((duration, index) => (
            <option key={index} value={duration} className="text-gray-800">
              {duration}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-6">
        <label className="block text-gray-400 mb-2">Generated Key</label>
        <div className="flex">
          <input
            type="text"
            className="w-full p-3 bg-gray-900 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
            value={key}
            readOnly
          />
          <motion.button
            onClick={generateKey}
            className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Generate Key
          </motion.button>
        </div>
      </div>
      <motion.button
        onClick={handleAddKey}
        className="w-full px-4 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Add Key
      </motion.button>
    </div>
  );
};

export default AdminAddKey;
