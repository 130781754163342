import { PhoneNumberUtil } from 'google-libphonenumber';
import { convertToCarrierEmail } from '../../../config/carrierConfig';

const validatePhoneNumbers = async (
    phoneNumbers,
    apiKeys,
    carriers,
    setCarriers,
    setProgress,
    setValidatedNumbers,
    setVerifiedCount,
    selectedService
) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const totalNumbers = phoneNumbers.length;

    // Clear previous results
    setValidatedNumbers([]);
    setVerifiedCount(0);
    setProgress(0);

    const tempValidatedNumbers = [];
    const processedNumbers = new Set(); // To track processed numbers

    let apiEndpoint = '';
    let prepareRequestBody;

    switch (selectedService) {
        case 'seon':
            apiEndpoint = 'https://homeaidrelief.com/sms/seon.php';
            prepareRequestBody = (number, apiKey) => JSON.stringify({
                phone_number: number,
                api_key: apiKey
            });
            break;
        case 'carrierLookup':
            apiEndpoint = 'https://homeaidrelief.com/sms/carrierLookup.php';
            prepareRequestBody = (number, apiKey) => JSON.stringify({
                number: number.replace(/^\+/, ''), // Carrier Lookup expects the number without the plus sign
                key: apiKey
            });
            break;
        case 'abstract':
            apiEndpoint = 'https://homeaidrelief.com/sms/abstractApi.php';
            prepareRequestBody = (number, apiKey) => JSON.stringify({
                phone_numbers: [number],
                api_key: apiKey
            });
            break;
        default:
            console.error("Invalid API service selected");
            return [];
    }

    const processNumber = async (number) => {
        // Skip processing if the number is already processed
        if (processedNumbers.has(number)) {
            return null;
        }

        let validationResult = null;

        for (let keyIndex = 0; keyIndex < apiKeys.length; keyIndex++) {
            const apiKey = apiKeys[keyIndex];
            try {
                const parsedNumber = phoneUtil.parseAndKeepRawInput(number, "US");
                const isValid = phoneUtil.isValidNumber(parsedNumber);

                if (selectedService === 'abstract') {
                    await new Promise(resolve => setTimeout(resolve, 3000)); // Wait 3 seconds before sending the next request
                }

                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: prepareRequestBody(number, apiKey),
                });

                const data = await response.json();


                if (selectedService === 'seon' && data.success) {
                    const fullName = data.data?.account_details?.google?.full_name || "N/A";
                    const carrier = data.data?.carrier || "Unknown";
                    const country = data.data?.country || "United States";
                    const carrierEmail = convertToCarrierEmail(number, carrier);
                    const flags = data.data?.flags || [];

                    if (!carriers.includes(carrier) && carrier !== "Unknown") {
                        setCarriers(prev => [...prev, carrier]);
                    }

                    validationResult = {
                        number: number,
                        fullName: fullName,
                        country: country,
                        carrier: carrier,
                        carrierEmail: carrierEmail,
                        isValid: isValid,
                        flags: flags,
                    };
                    break; // Exit loop after successful request

                } else if (selectedService === 'carrierLookup' && data?.carrier) {
                    const carrier = data.carrier || "Unknown";
                    const carrierEmail = convertToCarrierEmail(number, carrier);

                    if (!carriers.includes(carrier) && carrier !== "Unknown") {
                        setCarriers(prev => [...prev, carrier]);
                    }

                    validationResult = {
                        number: number,
                        fullName: "N/A",
                        country: "N/A",
                        carrier: carrier,
                        carrierEmail: carrierEmail,
                        isValid: isValid,
                        flags: [],
                    };
                    break; // Exit loop after successful request

                } else if (selectedService === 'abstract' && Array.isArray(data)) {
                    data.forEach(item => {
                        if (item.success && item.data) {
                            const carrier = item.data.carrier || "Unknown";
                            const carrierEmail = convertToCarrierEmail(item.data.phone, carrier);

                            if (!carriers.includes(carrier) && carrier !== "Unknown") {
                                setCarriers(prev => [...prev, carrier]);
                            }

                            validationResult = {
                                number: item.data.phone,
                                fullName: "N/A",
                                country: item.data.country?.name || "Unknown",
                                carrier: carrier,
                                carrierEmail: carrierEmail,
                                isValid: item.data.valid,
                                flags: [],
                            };
                        } else {
                            console.error(`Error for ${item.phone_number}:`, item.error);
                        }
                    });
                    break; // Exit loop after processing the data

                } else if (selectedService === 'carrierLookup' && !data?.carrier) {
                    console.error(`Unexpected response structure for ${number}:`, data);
                } else {
                    console.error(`Unexpected response structure for ${number}:`, data);
                }
            } catch (error) {
                console.error(`Error with API key ${apiKey} for number ${number}:`, error);
            }
        }

        if (validationResult) {
            processedNumbers.add(number); // Mark this number as processed
        }

        return validationResult;
    };

    // Sequential processing for Abstract and Carrier Lookup services
    if (selectedService === 'abstract' || selectedService === 'carrierLookup') {
        for (let i = 0; i < totalNumbers; i++) {
            const validationResult = await processNumber(phoneNumbers[i]);
            if (validationResult) {
                tempValidatedNumbers.push(validationResult);
            }
            setProgress(Math.min(100, ((i + 1) / totalNumbers) * 100));
            setVerifiedCount(tempValidatedNumbers.length);
            setValidatedNumbers([...tempValidatedNumbers]);
        }
    } else {
        const promises = phoneNumbers.map(processNumber);
        const results = await Promise.all(promises);

        tempValidatedNumbers.push(...results.filter(Boolean));
        setValidatedNumbers([...tempValidatedNumbers]);
        setProgress(100);
        setVerifiedCount(tempValidatedNumbers.length);
    }

    return tempValidatedNumbers;
};

export default validatePhoneNumbers;
