import React from 'react';

const FilterCarrier = ({ carriers, filterCarrier, handleFilterChange }) => {
  return (
    <div className="mt-4">
      <label htmlFor="carrier-filter" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        Filter by Carrier
      </label>
      <select
        id="carrier-filter"
        name="carrier-filter"
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        value={filterCarrier}
        onChange={(e) => handleFilterChange(e.target.value)}
      >
        <option value="">All Carriers</option>
        {carriers.map((carrier, index) => (
          <option key={index} value={carrier}>{carrier}</option>
        ))}
      </select>
    </div>
  );
};

export default FilterCarrier;
