// src/components/users/settings/Secure/Secure.js

import React from 'react';
import Question from './Question';

const Secure = ({ user }) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Left Side: Security Questions */}
      <div className="w-full md:w-1/2 p-4">
        <Question user={user} />
      </div>
      {/* Right Side: Empty for now */}
      <div className="w-full md:w-1/2 p-4">
        {/* You can add other future security features here */}
      </div>
    </div>
  );
};

export default Secure;
