import React, { useState, useEffect } from 'react';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/outline';
import { getAuth } from 'firebase/auth';
import { motion } from 'framer-motion';

const ValidatorSettings = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [selectedService, setSelectedService] = useState('seon');
  const [usedByUid, setUsedByUid] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  const auth = getAuth();

  const fetchUsedByUid = async () => {
    try {
      const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(accessKeyQuery);

      if (!querySnapshot.empty) {
        const accessKeyData = querySnapshot.docs[0].data();
        setUsedByUid(accessKeyData.usedBy);
      }
    } catch (error) {
      console.error("Error fetching usedBy UID:", error);
    }
  };

  useEffect(() => {
    fetchUsedByUid();
  }, []);

  useEffect(() => {
    const fetchApiKeys = async () => {
      if (!usedByUid) return;

      try {
        const validatorSettingsRef = doc(db, 'validatorSettings', usedByUid);
        const validatorSettingsSnapshot = await getDoc(validatorSettingsRef);
        if (validatorSettingsSnapshot.exists()) {
          const storedApiKeys = validatorSettingsSnapshot.data().apiKeys || [];

          // Convert string keys to object format if needed
          const formattedApiKeys = storedApiKeys.map(apiKey =>
            typeof apiKey === 'string' ? { service: 'unknown', key: apiKey } : apiKey
          );
          setApiKeys(formattedApiKeys);
        }
      } catch (error) {
        console.error("Error fetching API keys:", error);
      }
    };

    fetchApiKeys();
  }, [usedByUid]);

  const handleAddApiKey = () => {
    if (apiKeys.length < 5 && newApiKey.trim() !== '') {
      const newKeyEntry = { service: selectedService, key: newApiKey.trim() };
      setApiKeys([...apiKeys, newKeyEntry]);
      setNewApiKey('');
    }
  };

  const handleRemoveApiKey = async (index) => {
    const updatedApiKeys = apiKeys.filter((_, i) => i !== index);
    setApiKeys(updatedApiKeys);

    // Save the updated API keys to Firestore
    try {
      await setDoc(doc(db, 'validatorSettings', usedByUid), { apiKeys: updatedApiKeys });
      setAlertMessage('API key removed successfully!');
    } catch (error) {
      console.error("Error removing API key:", error);
      setAlertMessage('Error removing API key. Please try again.');
    }
  };

  const handleSave = async () => {
    if (!usedByUid) return;

    try {
      await setDoc(doc(db, 'validatorSettings', usedByUid), { apiKeys });
      setAlertMessage('API keys saved successfully!');
    } catch (error) {
      console.error("Error saving API keys:", error);
      setAlertMessage('Error saving API keys. Please try again.');
    }
  };

  return (
    <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold flex items-center space-x-3 mb-6">
        <PencilIcon className="h-8 w-8 text-blue-500" />
        <span>Validator Settings (API Keys)</span>
      </h2>

      <div className="space-y-6">
        <div className="flex items-center space-x-4 bg-gray-800 p-4 rounded-lg shadow-sm">
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className="bg-gray-700 text-white p-3 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
          >
            <option value="seon">Seon</option>
            <option value="carrierLookup">Carrier Lookup</option>
            <option value="abstract">Abstract</option>
          </select>

          <input
            type="text"
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
            placeholder="Add new API key"
            className="flex-grow bg-gray-700 text-white p-3 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={handleAddApiKey}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg flex items-center space-x-2"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add</span>
          </button>
        </div>

        <ul className="space-y-3">
          {apiKeys.map((apiKeyEntry, index) => (
            <li key={index} className="flex items-center justify-between p-4 bg-gray-800 rounded-lg shadow-sm">
              <span>{(apiKeyEntry.service || 'unknown').toUpperCase()}: {apiKeyEntry.key}</span>
              <button
                onClick={() => handleRemoveApiKey(index)}
                className="text-red-500 hover:text-red-700 flex items-center space-x-2"
              >
                <TrashIcon className="h-5 w-5" />
                <span>Remove</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {alertMessage && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {alertMessage}
        </motion.div>
      )}

      <motion.button
        onClick={handleSave}
        className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg flex items-center space-x-2"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <PencilIcon className="h-5 w-5" />
        <span>Save API Keys</span>
      </motion.button>
    </div>
  );
};

export default ValidatorSettings;
