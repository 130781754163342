// src/components/users/settings/Secure/ReauthenticateModal.js

import React, { useState } from 'react';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { auth } from '../../../../config/firebaseConfig';
import { XIcon } from '@heroicons/react/solid';

const ReauthenticateModal = ({ onClose, onSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleReauthenticate = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    try {
      await reauthenticateWithCredential(user, credential);
      onSuccess(); // Notify parent component of success
    } catch (err) {
      setError('Reauthentication failed. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-100">Reauthenticate</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>
        <form onSubmit={handleReauthenticate}>
          {error && <p className="text-red-500">{error}</p>}
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="w-full px-4 py-2 mb-4 border border-gray-600 rounded-md bg-gray-900 text-white"
          />
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReauthenticateModal;
