import { db } from './firebaseConfig'; // Adjust the import path according to your setup
import { doc, updateDoc, query, where, collection, getDocs } from 'firebase/firestore';
import Swal from 'sweetalert2'; // Assuming you're using SweetAlert2 for modals

export const checkAndDeductValidations = async (usedBy, numberOfValidations) => {
    try {
        if (!usedBy) {
            console.error('usedBy is undefined or null');
            return false;
        }

        // Query to find the AccessKey document based on usedBy field
        const accessKeysRef = collection(db, 'AccessKeys');
        const q = query(accessKeysRef, where('usedBy', '==', usedBy));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.error('No AccessKey document found for this user.');
            return false;
        }

        // Assuming one document per user
        const docSnapshot = querySnapshot.docs[0];
        const accessKeyRef = doc(db, 'AccessKeys', docSnapshot.id);

        if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            let validationCount = userData.allocation.validation;

            if (validationCount >= numberOfValidations) {
                // Deduct the number of validations required
                validationCount -= numberOfValidations;

                // Update the new validation count in Firestore
                await updateDoc(accessKeyRef, {
                    'allocation.validation': validationCount,
                });

                return true; // Allow the validation process to continue
            } else {
                // No more validations left, alert the user
                Swal.fire({
                    icon: 'warning',
                    title: 'Insufficient Validations',
                    text: `You only have ${validationCount} validations left. Please top up to continue.`,
                    showCancelButton: true,
                    confirmButtonText: 'Top Up',
                    cancelButtonText: 'Cancel',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the top-up page
                        window.location.href = '/top-up'; // Adjust the URL to your top-up page
                    }
                });

                return false; // Stop the validation process
            }
        } else {
            console.error('User document does not exist');
            return false;
        }
    } catch (error) {
        console.error('Error checking validation count:', error);
        return false;
    }
};
