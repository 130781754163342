// src/pages/Dashboard.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/layout/Layout';

const Dashboard = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<div>Welcome to your dashboard</div>} />
        {/* You can add more nested routes here as needed */}
      </Routes>
    </Layout>
  );
};

export default Dashboard;
