import React from 'react';
import {
  PhoneIcon,
  GlobeAltIcon,
  OfficeBuildingIcon,
  MailIcon,
  CheckCircleIcon,
  XIcon,
  FlagIcon,
} from '@heroicons/react/solid';

const PhoneTable = ({ filteredNumbers }) => {
  return (
    <div className="mt-6 overflow-x-auto overflow-y-auto max-h-96">
      <table className="min-w-full bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-600">
        <thead>
          <tr className="bg-gray-200 dark:bg-gray-700">
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <PhoneIcon className="h-5 w-5 mr-1" />
                Phone Number
              </div>
            </th>
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <GlobeAltIcon className="h-5 w-5 mr-1" />
                Country
              </div>
            </th>
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <OfficeBuildingIcon className="h-5 w-5 mr-1" />
                Carrier
              </div>
            </th>
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <MailIcon className="h-5 w-5 mr-1" />
                Carrier Email
              </div>
            </th>
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <CheckCircleIcon className="h-5 w-5 mr-1" />
                Is Valid
              </div>
            </th>
            <th className="py-2 px-4 border-b text-left text-gray-800 dark:text-gray-300">
              <div className="flex items-center">
                <FlagIcon className="h-5 w-5 mr-1" />
                Red Flags
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredNumbers.map((phone, index) => (
            <tr
              key={index}
              className={
                phone.isValid
                  ? 'bg-white dark:bg-gray-800'
                  : 'bg-red-100 dark:bg-red-700'
              }
            >
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                {phone.number}
              </td>
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                {phone.country || 'N/A'}
              </td>
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                {phone.carrier || 'N/A'}
              </td>
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                {phone.carrierEmail ? phone.carrierEmail.replace(/^\+1/, '') : 'N/A'}
              </td>
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                <div className="flex items-center">
                  {phone.isValid ? (
                    <CheckCircleIcon className="h-5 w-5 text-blue-500" />
                  ) : (
                    <XIcon className="h-5 w-5 text-red-500" />
                  )}
                </div>
              </td>
              <td className="py-2 px-4 border-b text-gray-800 dark:text-gray-300">
                {phone.flags && phone.flags.length > 0 ? (
                  phone.flags.map((flag, i) => (
                    <div key={i} className="mb-1">
                      <span className="block text-sm text-red-600">
                        Note: {flag.note}
                      </span>
                      <span className="block text-xs text-gray-600">
                        Industry: {flag.industry}
                      </span>
                      <span className="block text-xs text-gray-600">
                        Date: {new Date(flag.date * 1000).toLocaleDateString()}
                      </span>
                    </div>
                  ))
                ) : (
                  <span>No Flags</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PhoneTable;
