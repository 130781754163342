// src/components/users/dashboard/PlanOverview/PlanOverview.js

import React from 'react';

const PlanOverview = ({ plan, usage }) => {
  return (
    <div className="bg-purple-600 text-white p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">Plan Overview</h2>
      <p>Current Plan: {plan.name}</p>
      <p>Usage: {usage} of {plan.limit} SMS sent</p>
      <div className="mt-4">
        <div className="w-full bg-purple-800 rounded-full h-4">
          <div 
            className="bg-white h-4 rounded-full" 
            style={{ width: `${(usage / plan.limit) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PlanOverview;
