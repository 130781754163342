// src/pages/About.js

import React from 'react';
import Header from '../components/navs/Header';
import { FaRocket, FaShieldAlt, FaMobileAlt, FaEnvelopeOpenText, FaRegHandshake } from 'react-icons/fa';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div className="bg-gray-900 text-gray-100">
      <Header />
      <motion.main 
        className="container mx-auto mt-12 px-4 sm:px-6 lg:px-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <section className="text-center">
          <motion.h1 
            className="text-5xl font-extrabold text-gray-100"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            About Phonetor
          </motion.h1>
          <motion.p 
            className="mt-6 text-xl text-gray-400 max-w-3xl mx-auto"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Phonetor is a leading service provider offering fast, secure, and reliable SMS communication solutions for businesses and individuals.
            Our platform empowers you to manage your SMS campaigns with ease and efficiency, all from one centralized location.
          </motion.p>
        </section>

        <section className="mt-12">
          <motion.h2 
            className="text-4xl font-bold text-gray-100 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Our Mission
          </motion.h2>
          <motion.p 
            className="mt-4 text-lg text-gray-400 text-center max-w-2xl mx-auto"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            Our mission is to revolutionize communication by providing businesses with the tools they need to connect with their audience effortlessly.
            We strive to deliver services that are fast, reliable, and secure, ensuring that your messages reach the right people at the right time.
          </motion.p>
        </section>

        <section className="mt-12">
          <motion.h2 
            className="text-4xl font-bold text-gray-100 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.0 }}
          >
            What We Offer
          </motion.h2>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaRocket className="text-blue-500 w-12 h-12 mx-auto" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-100">SMS API Sender</h3>
              <p className="mt-2 text-gray-400">
                Integrate our robust API to send SMS messages directly from your own applications. Ideal for businesses needing automation and scalability.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaEnvelopeOpenText className="text-green-500 w-12 h-12 mx-auto" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-100">Email to SMS</h3>
              <p className="mt-2 text-gray-400">
                Send SMS messages directly to phone numbers using SMTP. Perfect for sending notifications and updates to your audience.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaShieldAlt className="text-red-500 w-12 h-12 mx-auto" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-100">Phone Validator & Converter</h3>
              <p className="mt-2 text-gray-400">
                Ensure accurate delivery with our phone number validation tool. Convert numbers to the correct format for SMS messaging.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaMobileAlt className="text-indigo-500 w-12 h-12 mx-auto" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-100">Mobile-Friendly Interface</h3>
              <p className="mt-2 text-gray-400">
                Manage your SMS campaigns from any device with our fully responsive, mobile-friendly platform.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaRegHandshake className="text-yellow-500 w-12 h-12 mx-auto" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-100">Reliable Support</h3>
              <p className="mt-2 text-gray-400">
                Our dedicated support team is here to help you every step of the way, ensuring your SMS campaigns are successful.
              </p>
            </motion.div>
          </div>
        </section>

        <section className="mt-12">
          <motion.h2 
            className="text-4xl font-bold text-gray-100 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.2 }}
          >
            Why Choose Us?
          </motion.h2>
          <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="text-2xl font-semibold text-gray-100">Fast and Secure</h3>
              <p className="mt-2 text-gray-400">
                We prioritize speed and security in our services, ensuring that your messages are delivered quickly and safely to your recipients.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="text-2xl font-semibold text-gray-100">Comprehensive Solutions</h3>
              <p className="mt-2 text-gray-400">
                From SMS API sending to email-to-SMS, phone validation, and more, our platform offers a complete suite of tools to meet your communication needs.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="text-2xl font-semibold text-gray-100">User-Friendly Interface</h3>
              <p className="mt-2 text-gray-400">
                Our platform is designed with simplicity and ease of use in mind, making it accessible to users of all skill levels.
              </p>
            </motion.div>
            <motion.div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="text-2xl font-semibold text-gray-100">Trusted by Businesses</h3>
              <p className="mt-2 text-gray-400">
                Many businesses trust Phonetor for their communication needs, thanks to our reliability, customer service, and advanced features.
              </p>
            </motion.div>
          </div>
        </section>

        <section className="mt-12">
          <motion.h2 
            className="text-4xl font-bold text-gray-100 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.4 }}
          >
            Our Team
          </motion.h2>
          <motion.p 
            className="mt-4 text-lg text-gray-400 text-center max-w-3xl mx-auto"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.6 }}
          >
            We are a team of dedicated professionals committed to providing the best SMS communication solutions. Our team is made up of experts in 
            technology, customer support, and innovation, working together to ensure you have the best experience possible.
          </motion.p>
        </section>

        <section className="mt-12">
          <motion.h2 
            className="text-4xl font-bold text-gray-100 text-center"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.8 }}
          >
            Get in Touch
          </motion.h2>
          <motion.p 
            className="mt-4 text-lg text-gray-400 text-center max-w-2xl mx-auto"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 2.0 }}
          >
            Have questions or need support? Reach out to our team anytime, and we’ll be happy to assist you. You can contact us via email, phone, or through our Telegram channel.
          </motion.p>
          <div className="mt-8 flex justify-center space-x-4">
            <motion.a 
              href="mailto:info@phonetor.com" 
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              Email Us
            </motion.a>
            <motion.a 
              href="tel:+1234567890" 
              className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              Call Us
            </motion.a>
            <motion.a 
              href="https://t.me/phonetor" 
              className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              Telegram
            </motion.a>
          </div>
        </section>
      </motion.main>
    </div>
  );
};

export default About;
