import React, { useState } from 'react';
import SMTPSettings from './SMTPSettings/SMTPSettings';
import SMSAPISettings from './SMSAPISettings/SMSAPISettings';
import ValidatorSettings from './ValidatorSettings/ValidatorSettings';
import TelegramSettings from './TelegramSettings/TelegramSettings';
import Secure from './Secure/Secure'; // Importing the Secure component
import { XIcon, MailIcon, DeviceMobileIcon, ClipboardCheckIcon, ChatAlt2Icon, ShieldCheckIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

const SettingsModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('smtp');

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'smtp':
        return <SMTPSettings />;
      case 'smsApi':
        return <SMSAPISettings />;
      case 'validator':
        return <ValidatorSettings />;
      case 'telegram':
        return <TelegramSettings />;
      case 'secure':  // Adding case for the Secure tab
        return <Secure />;
      default:
        return <SMTPSettings />;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-3xl overflow-y-auto max-h-full"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Settings</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => setActiveTab('smtp')}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              activeTab === 'smtp' ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
            }`}
          >
            <MailIcon className="h-5 w-5 text-blue-500" />
            <span>SMTP</span>
          </button>
          <button
            onClick={() => setActiveTab('smsApi')}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              activeTab === 'smsApi' ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
            }`}
          >
            <DeviceMobileIcon className="h-5 w-5 text-green-500" />
            <span>SMS API</span>
          </button>
          <button
            onClick={() => setActiveTab('validator')}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              activeTab === 'validator' ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
            }`}
          >
            <ClipboardCheckIcon className="h-5 w-5 text-yellow-500" />
            <span>Validator API</span>
          </button>
          <button
            onClick={() => setActiveTab('telegram')}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              activeTab === 'telegram' ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
            }`}
          >
            <ChatAlt2Icon className="h-5 w-5 text-purple-500" />
            <span>Telegram Bot</span>
          </button>
          <button
            onClick={() => setActiveTab('secure')} // Secure Tab
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              activeTab === 'secure' ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
            }`}
          >
            <ShieldCheckIcon className="h-5 w-5 text-red-500" />
            <span>Secure</span>
          </button>
        </div>
        <div className="mt-4 overflow-y-auto max-h-[70vh] smooth-scroll">
          {renderActiveTab()}
        </div>
      </motion.div>
    </div>
  );
};

export default SettingsModal;
