import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from './authContext';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { XIcon } from '@heroicons/react/solid';
import { doc, setDoc, getDocs, where, query, collection } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const AuthKeyModal = ({ onClose }) => {
  const { storeInLocalStorage, currentUser } = useAuth();
  const [authKey, setAuthKey] = useState(['', '', '', '', '', '']);
  const [rememberKey, setRememberKey] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [browserFingerprint, setBrowserFingerprint] = useState('');
  const inputRefs = useRef([]);

  useEffect(() => {
    const loadFingerprint = async () => {
      // Check if the fingerprint is already stored locally
      const storedFingerprint = localStorage.getItem('browserFingerprint');
      if (storedFingerprint) {
        setBrowserFingerprint(storedFingerprint);
      } else {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setBrowserFingerprint(result.visitorId);
        localStorage.setItem('browserFingerprint', result.visitorId); // Store fingerprint locally
      }
    };

    loadFingerprint();
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
  
    // Handle backspace
    if (e.key === 'Backspace' || value === '') {
      const newAuthKey = [...authKey];
      newAuthKey[index] = ''; // Clear the current input
      setAuthKey(newAuthKey);
  
      // Move focus to the previous input if available
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  
    // Handle valid digit input
    else if (/^\d$/.test(value)) {
      const newAuthKey = [...authKey];
      newAuthKey[index] = value;
      setAuthKey(newAuthKey);
  
      // Move focus to the next input if available
      if (index < 5 && value) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const key = authKey.join('');
  
    setLoading(true);
    try {
      // Check if the entered auth key is valid in Firestore
      const accessKeyQuery = query(
        collection(db, 'AccessKeys'),
        where('key', '==', key)
      );
      const querySnapshot = await getDocs(accessKeyQuery);
  
      if (querySnapshot.empty) {
        setError('Invalid Auth Key. Please try again.');
        setAuthKey(['', '', '', '', '', '']);  // Clear the authKey input
        inputRefs.current[0].focus();  // Focus back to the first input
        setLoading(false);
        return;
      }
  
      // Store in localStorage and Firestore
      storeInLocalStorage(key, browserFingerprint);
  
      if (rememberKey) {
        const rememberUntil = new Date();
        rememberUntil.setDate(rememberUntil.getDate() + 30);
  
        const userRef = doc(db, 'Users', currentUser.uid);
        await setDoc(userRef, {
          rememberAuthKeyUntil: rememberUntil,
          browserFingerprint,
        }, { merge: true });
      }
  
      window.location.reload(); // Reload after successful submission
    } catch (err) {
      console.error('Auth Key Error:', err);
      setError('Invalid Auth Key. Please try again.');
    }
  
    setLoading(false);
  };
  
  
  

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="bg-gray-800 text-gray-100 p-6 rounded-lg shadow-lg w-full max-w-md"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Enter Your Auth Key</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
            </svg>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex justify-between">
              {authKey.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  ref={(el) => inputRefs.current[index] = el}
                  className="w-12 h-12 text-center border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                />
              ))}
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={rememberKey}
                onChange={(e) => setRememberKey(e.target.checked)}
              />
              <label className="text-gray-400">Remember this Auth Key for 30 days</label>
            </div>
            <motion.button
              type="submit"
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Submit
            </motion.button>
          </form>
        )}
      </motion.div>
    </div>
  );
};

export default AuthKeyModal;
