// src/components/users/dashboard/RecentActivity/RecentActivity.js

import React from 'react';

const RecentActivity = ({ activities }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-semibold text-white mb-4">Recent Activity</h2>
      <ul className="space-y-4">
        {activities.length === 0 ? (
          <p className="text-gray-400">No recent activity.</p>
        ) : (
          activities.map((activity, index) => (
            <li key={index} className="bg-gray-700 p-4 rounded-md text-gray-200">
              {activity.description}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default RecentActivity;
