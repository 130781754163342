import React from 'react';
import { UploadIcon, ChatIcon, LinkIcon, PaperAirplaneIcon, MailIcon, ServerIcon, LinkIcon as LinkOnIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react';
import { motion } from 'framer-motion';

const PhonetorUI = ({ 
  useSmtp, 
  linkEnabled, 
  contacts, 
  message, 
  handleFileChange, 
  handleMessageChange, 
  handleToggleSMTP, 
  handleToggleLink, 
  handleSendMessages, 
  progressFeed, 
  sendingProgress  // This tracks sending progress for the progress bar
}) => {
  return (
    <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
      {/* Left Section */}
      <motion.div
        className="w-full md:w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-semibold text-white mb-6 flex items-center space-x-2">
          <ChatIcon className="h-6 w-6 text-yellow-500" />
          <span>Phonetor</span>
        </h2>

        <div className="mb-6">
          <label className="block text-white mb-2">Upload Contacts</label>
          <div className="flex items-center">
            <input
              type="file"
              accept=".txt,.csv"
              onChange={handleFileChange}
              className="bg-gray-900 text-white p-2 rounded-l-lg border border-gray-600 w-full"
            />
            <button className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-r-lg flex items-center space-x-2">
              <UploadIcon className="h-5 w-5" />
              <span>Upload</span>
            </button>
          </div>
        </div>

        <div className="mb-6">
          <p className="text-white font-medium">Uploaded Contacts: {contacts.length}</p>
        </div>

        <div className="mb-4">
          <textarea
            value={message}
            onChange={handleMessageChange}
            rows="4"
            placeholder="Enter your message here..."
            className="bg-gray-900 text-white p-3 rounded-lg border border-gray-600 w-full"
            maxLength={160}
          />
          <p className="text-sm text-gray-400 mt-1 text-right">{message.length}/160</p>
        </div>

        <div className="flex items-center justify-between mb-6 space-x-4">
          {/* SMTP Toggle */}
          <Switch.Group>
            <div className="flex items-center">
              <Switch.Label className="mr-2 text-white">{useSmtp ? 'Use SMTP' : 'Use API'}</Switch.Label>
              <Switch
                checked={useSmtp}
                onChange={handleToggleSMTP}
                className={`${
                  useSmtp ? 'bg-blue-500' : 'bg-gray-700'
                } relative inline-flex items-center h-6 rounded-full w-16 transition-colors duration-200`}
              >
                <span
                  className={`${
                    useSmtp ? 'translate-x-10' : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
                />
              </Switch>
              {useSmtp ? (
                <MailIcon className="h-5 w-5 text-blue-500 ml-2" />
              ) : (
                <ServerIcon className="h-5 w-5 text-gray-500 ml-2" />
              )}
            </div>
          </Switch.Group>

          {/* Link Toggle */}
          <Switch.Group>
            <div className="flex items-center">
              <Switch.Label className="mr-2 text-white">{linkEnabled ? 'Link On' : 'Link Off'}</Switch.Label>
              <Switch
                checked={linkEnabled}
                onChange={handleToggleLink}
                className={`${
                  linkEnabled ? 'bg-green-500' : 'bg-gray-700'
                } relative inline-flex items-center h-6 rounded-full w-16 transition-colors duration-200`}
              >
                <span
                  className={`${
                    linkEnabled ? 'translate-x-10' : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
                />
              </Switch>
              {linkEnabled ? (
                <LinkOnIcon className="h-5 w-5 text-green-500 ml-2" />
              ) : (
                <LinkIcon className="h-5 w-5 text-gray-500 ml-2" />
              )}
            </div>
          </Switch.Group>

          {/* Send Button */}
          <button
            onClick={handleSendMessages}
            className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg flex items-center space-x-2"
          >
            <PaperAirplaneIcon className="h-5 w-5 transform rotate-45" />
            <span>Send</span>
          </button>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-gray-300 rounded-full h-4 mt-4">
          <div className="bg-blue-600 text-xs leading-none text-center text-white rounded-full"
            style={{ width: `${sendingProgress}%`, height: '100%' }}
          >
            {sendingProgress}%
          </div>
        </div>

      </motion.div>


      {/* Right Section */}
      <motion.div
        className="w-full md:w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-semibold text-white mb-6 flex items-center space-x-2">
          <LinkIcon className="h-6 w-6 text-green-500" />
          <span>Sending Progress</span>
        </h2>
        <div className="bg-gray-900 p-4 rounded-lg h-full overflow-y-auto smooth-scroll">
          {progressFeed.length === 0 ? (
            <p className="text-gray-400">No progress yet.</p>
          ) : (
            progressFeed.map((feed, index) => (
              <div key={index} className="text-white mb-2">
                {feed.status}
              </div>
            ))
          )}
        </div>
      </motion.div>

    </div>
  );
};

export default PhonetorUI;
