import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, InformationCircleIcon, PhoneIcon, DeviceMobileIcon } from '@heroicons/react/outline';

const Header = () => {
  return (
    <header className="bg-gradient-to-r from-gray-800 via-gray-700 to-gray-900 text-gray-100 shadow-lg sticky top-0 z-50">
      <nav className="container mx-auto flex justify-between items-center px-6 py-4">
        <div className="text-2xl font-bold flex items-center space-x-2">
          {/* Red Mobile Phone Icon */}
          <DeviceMobileIcon className="w-8 h-8 text-red-500" />

          {/* Phonetor text set to white, hidden on small screens (mobile) */}
          <span
            className="hidden md:inline-block text-white font-extrabold tracking-wide text-2xl"
            style={{
              fontFamily: 'Poppins, sans-serif', // Custom font
            }}
          >
            Phonetor
          </span>
        </div>

        <div className="flex space-x-6">
          <Link to="/" className="flex items-center space-x-1 hover:text-blue-400 transition duration-300">
            <HomeIcon className="w-6 h-6" />
            <span>Home</span>
          </Link>
          <Link to="/about" className="flex items-center space-x-1 hover:text-blue-400 transition duration-300">
            <InformationCircleIcon className="w-6 h-6" />
            <span>About</span>
          </Link>
          <Link to="/contact" className="flex items-center space-x-1 hover:text-blue-400 transition duration-300">
            <PhoneIcon className="w-6 h-6" />
            <span>Contact</span>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
