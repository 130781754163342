import React from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon, UploadIcon, ClipboardCheckIcon, FilterIcon, DownloadIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

const HowToUse = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <motion.div
          className="bg-gray-900 text-white p-8 rounded-lg shadow-lg max-w-lg w-full relative"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-white"
          >
            <XIcon className="h-6 w-6" />
          </button>

          <Dialog.Title className="text-3xl font-bold mb-6 flex items-center space-x-2">
            <InformationCircleIcon className="h-8 w-8 text-blue-500" />
            <span>How to Use the Phone Number Validator</span>
          </Dialog.Title>

          <div className="space-y-6 overflow-y-auto max-h-96 pr-2 smooth-scroll">
            {/* Section 1: Uploading a File */}
            <div className="p-4 bg-gray-800 rounded-lg space-y-3">
              <div className="flex items-start space-x-3">
                <UploadIcon className="h-6 w-6 text-green-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 1: Uploading a File</h3>
                  <p>Start by uploading a .txt or .csv file containing the phone numbers you want to validate.</p>
                  <p className="text-sm text-gray-400"><strong>Tip:</strong> Ensure your file is properly formatted with one phone number per line.</p>
                </div>
              </div>
            </div>

            {/* Section 2: Adding Country Code */}
            <div className="p-4 bg-gray-800 rounded-lg space-y-3">
              <div className="flex items-start space-x-3">
                <ClipboardCheckIcon className="h-6 w-6 text-yellow-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 2: Adding Country Code</h3>
                  <p>Choose if you want to add a country code to the phone numbers:</p>
                  <ul className="ml-6 list-disc text-sm">
                    <li>If numbers are in the format <strong>8062023844</strong>, no changes are needed, perfect for <em>Carrier Lookup</em>.</li>
                    <li>If numbers are in the format <strong>18062023844</strong>, you may leave them as they are, perfect for <em>Seon & Abstract</em>.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Section 3: Choosing Validation Service */}
            <div className="p-4 bg-gray-800 rounded-lg space-y-3">
              <div className="flex items-start space-x-3">
                <FilterIcon className="h-6 w-6 text-purple-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 3: Choosing Validation Service</h3>
                  <p>Select the service for validation:</p>
                  <ul className="ml-6 list-disc text-sm">
                    <li><strong>SEON</strong>: Fast with 70%-80% accuracy, ideal for quick checks.</li>
                    <li><strong>Abstract</strong>: Slower, but 80%-100% accuracy, recommended for high precision.</li>
                    <li><strong>Carrier Lookup</strong>: Slowest, but 90%-100% accuracy, best for detailed validation.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Section 4: Validating Numbers */}
            <div className="p-4 bg-gray-800 rounded-lg space-y-3">
              <div className="flex items-start space-x-3">
                <ClipboardCheckIcon className="h-6 w-6 text-blue-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 4: Validating Numbers</h3>
                  <p>Press the "Validate Numbers" button to begin. The progress bar will show how far along the validation is.</p>
                  <p className="text-sm text-red-400"><strong>Important:</strong> Stay on the page while the validation is ongoing to avoid interruptions and unnecessary token usage.</p>
                </div>
              </div>
            </div>

            {/* Section 5: Filtering and Downloading Results */}
            <div className="p-4 bg-gray-800 rounded-lg space-y-3">
              <div className="flex items-start space-x-3">
                <FilterIcon className="h-6 w-6 text-orange-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 5: Filtering and Downloading Results</h3>
                  <p>Filter the results by carrier if necessary.</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <DownloadIcon className="h-6 w-6 text-teal-500" />
                <div>
                  <h3 className="font-semibold text-lg">Step 6: Downloading Results</h3>
                  <p>Once validation is complete, download the results in CSV format for further use.</p>
                  <p className="text-sm text-gray-400"><strong>Tip:</strong> You can copy the filtered emails directly to the clipboard if needed.</p>
                </div>
              </div>
            </div>
            
            <p className="text-gray-400 text-sm">If you encounter any issues, don’t hesitate to contact our support team for assistance.</p>
          </div>
        </motion.div>
      </div>
    </Dialog>
  );
};

export default HowToUse;
