import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { auth } from '../../config/firebaseConfig'; // Firebase auth import
import { sendPasswordResetEmail } from 'firebase/auth';
import KeyRecovery from './KeyRecovery'; // Import KeyRecovery modal

const PasswordResetModal = ({ onClose, currentUser }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); // Track errors
  const [loading, setLoading] = useState(false); // Track loading state
  const [showKeyRecovery, setShowKeyRecovery] = useState(false); // Handle key recovery modal state

  // Handle Password Reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage(null);
      setError('Please enter your email.');
      return;
    }

    console.log('Attempting to send password reset email to:', email);

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
      console.log('Password reset email successfully sent');
    } catch (err) {
      console.error('Password reset error:', err);
      switch (err.code) {
        case 'auth/user-not-found':
          setError('No user found with this email.');
          console.log('Error: No user found for the given email');
          break;
        case 'auth/invalid-email':
          setError('Invalid email address.');
          console.log('Error: Invalid email address');
          break;
        default:
          setError('Failed to send password reset email. Please try again.');
          console.log('Unknown error occurred during password reset');
      }
    } finally {
      setLoading(false);
      console.log('Password reset process completed');
    }
  };

  const handleShowKeyRecovery = () => {
    setShowKeyRecovery(true);
  };

  return (
    <>
      {showKeyRecovery ? (
        <KeyRecovery onClose={onClose} currentUser={currentUser} />
      ) : (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-100">Reset Password</h2>
              <button onClick={onClose} className="focus:outline-none">
                <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
              </button>
            </div>
            <form onSubmit={handlePasswordReset}>
              {message && <p className="text-sm text-green-500 mb-4">{message}</p>}
              {error && <p className="text-sm text-red-500 mb-4">{error}</p>}
              <div className="mb-4">
                <label className="block text-gray-400">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={loading}
                >
                  {loading ? 'Sending...' : 'Send Reset Link'}
                </button>
              </div>
            </form>

            {/* Key Recovery Button */}
            <div className="mt-6 text-center">
              <button
                onClick={handleShowKeyRecovery}
                className="text-sm text-blue-400 hover:underline focus:outline-none"
              >
                Recover Key
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordResetModal;
