import { db } from './firebaseConfig'; // Adjust the import path according to your setup
import { doc, updateDoc, query, where, collection, getDocs } from 'firebase/firestore';
import Swal from 'sweetalert2'; // Assuming you're using SweetAlert2 for modals

// Function to check SMS count but only deduct after successful sending
export const checkSMSCount = async (usedBy, numberOfSMS) => {
    try {
        if (!usedBy) {
            console.error('usedBy is undefined or null');
            return false;
        }

        // Query to find the AccessKey document based on usedBy field
        const accessKeysRef = collection(db, 'AccessKeys');
        const q = query(accessKeysRef, where('usedBy', '==', usedBy));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.error('No AccessKey document found for this user.');
            return false;
        }

        // Assuming one document per user
        const docSnapshot = querySnapshot.docs[0];
        if (!docSnapshot.exists) {
            console.error('User document does not exist');
            return false;
        }

        const userData = docSnapshot.data();
        let smsCount = userData.allocation.sms;

        if (smsCount >= numberOfSMS) {
            return true; // Sufficient credits, proceed to send SMS
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Insufficient SMS Credits',
                text: `You only have ${smsCount} SMS left. Please top up to continue.`,
                showCancelButton: true,
                confirmButtonText: 'Top Up',
                cancelButtonText: 'Cancel',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/top-up'; // Adjust the URL to your top-up page
                }
            });

            return false;
        }
    } catch (error) {
        console.error('Error checking SMS count:', error);
        return false;
    }
};

// Function to deduct SMS credits after successful sending
export const deductSMSCredits = async (usedBy, numberOfSMS) => {
    try {
        // Query to find the AccessKey document based on usedBy field
        const accessKeysRef = collection(db, 'AccessKeys');
        const q = query(accessKeysRef, where('usedBy', '==', usedBy));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.error('No AccessKey document found for this user.');
            return false;
        }

        // Assuming one document per user
        const docSnapshot = querySnapshot.docs[0];
        const accessKeyRef = doc(db, 'AccessKeys', docSnapshot.id);
        const userData = docSnapshot.data();
        let smsCount = userData.allocation.sms;

        // Deduct the number of SMS required
        smsCount -= numberOfSMS;

        // Update the new SMS count in Firestore
        await updateDoc(accessKeyRef, {
            'allocation.sms': smsCount,
        });

        return true;
    } catch (error) {
        console.error('Error deducting SMS credits:', error);
        return false;
    }
};
