import { useState, useEffect } from 'react';
import { auth } from '../../../config/firebaseConfig';  // Assuming Firebase setup for getting the user

export const usePhonetorState = () => {
  const [contacts, setContacts] = useState([]);
  const [message, setMessage] = useState('');
  const [linkEnabled, setLinkEnabled] = useState(false);
  const [useSmtp, setUseSmtp] = useState(false);
  const [command, setCommand] = useState({ useSMTP: '#0', linkOn: '#0' });
  const [selectedSMSAPI, setSelectedSMSAPI] = useState(''); // State for selected API
  const [smtpDetails, setSmtpDetails] = useState(''); // State for SMTP Details
  const [usedBy, setUsedBy] = useState(null); // State for user (usedBy)

  useEffect(() => {
    const storedSettings = JSON.parse(localStorage.getItem('phonetorSettings'));

    if (storedSettings?.command) {
      setUseSmtp(storedSettings.command.useSMTP === '#1');
      setLinkEnabled(storedSettings.command.linkOn === '#1');
      setSelectedSMSAPI(storedSettings.smsAPIDetails || ''); // Fetch the full API details
      setSmtpDetails(storedSettings.smtpDetails || ''); // Fetch SMTP details
    }

    // Fetch the authenticated user's UID
    const user = auth.currentUser;
    if (user) {
      setUsedBy(user.uid); // Set the `usedBy` to the authenticated user's UID
    }
  }, []);

  useEffect(() => {
    const updatedCommand = {
      useSMTP: useSmtp ? '#1' : '#0',
      linkOn: linkEnabled ? '#1' : '#0',
    };
    setCommand(updatedCommand);

    const storedSettings = JSON.parse(localStorage.getItem('phonetorSettings')) || {};
    storedSettings.command = updatedCommand;
    localStorage.setItem('phonetorSettings', JSON.stringify(storedSettings));
  }, [useSmtp, linkEnabled]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      parseContacts(selectedFile).then(parsedContacts => setContacts(parsedContacts));
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value.slice(0, 160));
  };

  const parseContacts = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const fileContent = event.target.result;
        const parsedContacts = fileContent.split('\n').map(contact => contact.trim()); // Split contacts by newline
        resolve(parsedContacts);
      };
      reader.onerror = () => {
        reject('Error reading file.');
      };
      reader.readAsText(file);
    });
  };

  return {
    contacts,
    message,
    linkEnabled,
    useSmtp,
    setLinkEnabled,
    setUseSmtp,
    handleFileChange,
    handleMessageChange,
    selectedSMSAPI,
    smtpDetails,
    setSelectedSMSAPI,  // Add setter for API selection
    setSmtpDetails,     // Add setter for SMTP details
    usedBy,  // Return `usedBy` for validation check
  };
};
