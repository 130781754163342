import React from 'react';
import { PaperAirplaneIcon, ClipboardCheckIcon, BadgeCheckIcon, CalendarIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

const WelcomeBanner = ({ userName, allocation, category, expiresAt }) => {
  return (
    <motion.div 
      className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white p-8 rounded-lg shadow-lg mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-4">Welcome back, {userName}!</h1>
      <p className="text-lg mb-6">Here's a quick overview of your account:</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* SMS Allocation */}
        <motion.div 
          className="flex items-center space-x-4 bg-blue-800 p-6 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <PaperAirplaneIcon className="h-10 w-10 text-green-400" />
          <div>
            <p className="text-lg font-medium">SMS Remaining</p>
            <p className="text-3xl font-bold">{allocation.sms} SMS</p>
          </div>
        </motion.div>

        {/* Validation Allocation */}
        <motion.div 
          className="flex items-center space-x-4 bg-blue-800 p-6 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <ClipboardCheckIcon className="h-10 w-10 text-yellow-400" />
          <div>
            <p className="text-lg font-medium">Validations Remaining</p>
            <p className="text-3xl font-bold">{allocation.validation} Validations</p>
          </div>
        </motion.div>

        {/* Subscription Category */}
        <motion.div 
          className="flex items-center space-x-4 bg-blue-800 p-6 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <BadgeCheckIcon className="h-10 w-10 text-purple-400" />
          <div>
            <p className="text-lg font-medium">Category</p>
            <p className="text-3xl font-bold">{category}</p>
          </div>
        </motion.div>

        {/* Expiration Date */}
        <motion.div 
          className="flex items-center space-x-4 bg-blue-800 p-6 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <CalendarIcon className="h-10 w-10 text-red-400" />
          <div>
            <p className="text-lg font-medium">Expires On</p>
            <p className="text-3xl font-bold">{expiresAt.toLocaleDateString()}</p>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default WelcomeBanner;
