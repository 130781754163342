export const sendSmtpSms = async (smtpDetails, recipientEmail, message) => {
    try {
        // Ensure required SMTP details are present
        if (!smtpDetails.host || !smtpDetails.user || !smtpDetails.password) {
            console.error('Missing SMTP details');
            return { success: false, error: 'Missing SMTP details: Ensure host, user, and password are provided.' };
        }

        // Make the SMTP request
        const response = await fetch('https://api.phonetor.com/smtpSendSms.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                smtpHost: smtpDetails.host,
                smtpPort: smtpDetails.port,
                smtpUser: smtpDetails.user,
                smtpPassword: smtpDetails.password,
                mailFrom: smtpDetails.from,
                fromName: smtpDetails.user,
                messageContent: message,
                recipients: [recipientEmail],
            }),
        });

        // Check if the response is valid JSON
        if (!response.ok) {
            console.error(`Failed to send SMTP SMS. HTTP error: ${response.statusText}`);
            return { success: false, error: `HTTP error: ${response.statusText}` };
        }

        const result = await response.json();

        if (result.status === 'success') {
            return { success: true };
        } else if (result.message) {
            console.error(`SMTP Error: ${result.message}`);
            return { success: false, error: result.message };
        } else {
            console.error('Unknown error during SMTP SMS sending.');
            return { success: false, error: 'Unknown error during SMTP SMS sending.' };
        }
    } catch (error) {
        console.error('Error during SMTP SMS sending:', error);
        return { success: false, error: `Exception: ${error.message}` };
    }
};
