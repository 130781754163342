import React from 'react';
import { FaQuestionCircle, FaLifeRing, FaBook } from 'react-icons/fa';
import { motion } from 'framer-motion';

const SupportSection = () => {
  return (
    <motion.div
      className="bg-gray-900 p-8 rounded-lg shadow-lg mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-bold text-white mb-6 flex items-center">
        <FaLifeRing className="text-blue-500 mr-3" /> Need Help?
      </h2>
      <ul className="space-y-6">
        <li>
          <a
            href="#"
            className="flex items-center text-blue-400 hover:underline transition duration-300"
          >
            <FaQuestionCircle className="mr-3" /> Help Center
          </a>
        </li>
        <li>
          <a
            href="#"
            className="flex items-center text-blue-400 hover:underline transition duration-300"
          >
            <FaLifeRing className="mr-3" /> Contact Support
          </a>
        </li>
        <li>
          <a
            href="#"
            className="flex items-center text-blue-400 hover:underline transition duration-300"
          >
            <FaBook className="mr-3" /> View Documentation
          </a>
        </li>
      </ul>
    </motion.div>
  );
};

export default SupportSection;
