import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './authContext';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading, keyRecoveryMode } = useAuth();
  if (loading) {
    return <div>Loading...</div>; // Or a full-screen spinner, etc.
  }

  // If in key recovery mode, allow access without redirection
  if (keyRecoveryMode) {
    return children;
  }

  // Redirect to home if not authenticated
  return currentUser ? children : <Navigate to="/" />;
};

export default PrivateRoute;
