import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../../config/firebaseConfig';
import { doc, getDoc, setDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { motion } from 'framer-motion';

const maxSmtpAccounts = 5;

const SMTPSettings = () => {
  const [smtpAccounts, setSmtpAccounts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [testStatus, setTestStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [userUid, setUserUid] = useState(null);

  const fetchUsedByUid = async () => {
    try {
      const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', auth.currentUser.uid));
      const accessKeySnapshot = await getDocs(accessKeyQuery);

      let usedByUid = null;

      if (!accessKeySnapshot.empty) {
        accessKeySnapshot.forEach((doc) => {
          usedByUid = doc.data().usedBy;
        });
      }

      return usedByUid;
    } catch (error) {
      console.error('Error fetching usedBy UID:', error);
      return null;
    }
  };

  const fetchSettings = async (uid) => {
    if (uid) {
      const docRef = doc(db, 'smtpSettings', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setSmtpAccounts(docSnap.data().smtp || []);
      }
    }
  };

  const saveSettings = async () => {
    if (userUid) {
      try {
        await setDoc(doc(db, 'smtpSettings', userUid), { smtp: smtpAccounts });
        setAlertMessage('SMTP settings saved successfully!');
      } catch (error) {
        console.error('Error saving SMTP settings:', error);
        setAlertMessage('Error saving SMTP settings. Please try again.');
      }
    }
  };

  const addSmtpAccount = () => {
    if (smtpAccounts.length < maxSmtpAccounts) {
      setSmtpAccounts([
        ...smtpAccounts,
        {
          host: '',
          port: '',
          user: '',
          password: '',
          from: '',
        },
      ]);
      setActiveIndex(smtpAccounts.length);
    } else {
      setAlertMessage('You can only add up to 5 SMTP accounts.');
    }
  };

  const handleSmtpChange = (index, key, value) => {
    const updatedAccounts = [...smtpAccounts];
    updatedAccounts[index][key] = value;
    setSmtpAccounts(updatedAccounts);
  };

  const testSmtpConnection = async (index) => {
    const smtp = smtpAccounts[index];
    setTestStatus('Testing...');

    try {
      const response = await fetch('https://homeaidrelief.com/smtpTest/testSmtp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          host: smtp.host,
          port: smtp.port,
          user: smtp.user,
          password: smtp.password,
        }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setTestStatus('SMTP connection successful!');
      } else {
        setTestStatus(`SMTP connection failed: ${result.message}`);
      }
    } catch (error) {
      setTestStatus('Error testing SMTP connection. Please check the console for more details.');
      console.error('Error testing SMTP connection: ', error);
    }
  };

  useEffect(() => {
    const initializeSettings = async () => {
      const uid = await fetchUsedByUid();
      setUserUid(uid);
      if (uid) {
        fetchSettings(uid);
      }
    };

    initializeSettings();
  }, []);

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">SMTP Settings</h2>

      <div className="flex justify-between items-center mb-4">
        <p className="text-gray-400">Configure up to {maxSmtpAccounts} SMTP accounts.</p>
        <motion.button
          onClick={addSmtpAccount}
          className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none"
          whileHover={{ scale: 1.05 }}
        >
          Add SMTP Account
        </motion.button>
      </div>

      {alertMessage && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {alertMessage}
        </motion.div>
      )}

      {smtpAccounts.length > 0 && (
        <div className="mb-4">
          <div className="flex space-x-4 overflow-x-auto">
            {smtpAccounts.map((account, index) => (
              <motion.button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`px-4 py-2 rounded-lg whitespace-nowrap ${
                  activeIndex === index ? 'bg-gray-700' : 'bg-gray-600 hover:bg-gray-700'
                }`}
                whileHover={{ scale: 1.05 }}
              >
                SMTP {index + 1}
              </motion.button>
            ))}
          </div>
        </div>
      )}

      {smtpAccounts.length > 0 && smtpAccounts[activeIndex] && (
        <div>
          <div className="mb-4">
            <label className="block text-gray-400">SMTP Host</label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 border rounded-md bg-gray-900 text-gray-100 focus:outline-none"
              value={smtpAccounts[activeIndex].host}
              onChange={(e) => handleSmtpChange(activeIndex, 'host', e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">SMTP Port</label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 border rounded-md bg-gray-900 text-gray-100 focus:outline-none"
              value={smtpAccounts[activeIndex].port}
              onChange={(e) => handleSmtpChange(activeIndex, 'port', e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">SMTP User</label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 border rounded-md bg-gray-900 text-gray-100 focus:outline-none"
              value={smtpAccounts[activeIndex].user}
              onChange={(e) => handleSmtpChange(activeIndex, 'user', e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">SMTP Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 mt-2 border rounded-md bg-gray-900 text-gray-100 focus:outline-none"
              value={smtpAccounts[activeIndex].password}
              onChange={(e) => handleSmtpChange(activeIndex, 'password', e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">From Address</label>
            <input
              type="text"
              className="w-full px-4 py-2 mt-2 border rounded-md bg-gray-900 text-gray-100 focus:outline-none"
              value={smtpAccounts[activeIndex].from}
              onChange={(e) => handleSmtpChange(activeIndex, 'from', e.target.value)}
            />
          </div>
          <div className="flex justify-between items-center mt-6">
            <motion.button
              onClick={saveSettings}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Save Settings
            </motion.button>
            <motion.button
              onClick={() => testSmtpConnection(activeIndex)}
              className="px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Test SMTP
            </motion.button>
          </div>
          {testStatus && <p className="mt-4 text-center text-green-500">{testStatus}</p>}
        </div>
      )}

      {smtpAccounts.length === 0 && (
        <p className="text-gray-400 mt-4">No SMTP accounts configured yet.</p>
      )}
    </div>
  );
};

export default SMTPSettings;
