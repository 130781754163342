import React, { useState } from 'react';
import { DeviceMobileIcon, ClipboardCheckIcon, LightningBoltIcon, ShieldCheckIcon } from '@heroicons/react/outline';
import { motion, AnimatePresence } from 'framer-motion';

const QuickAccess = () => {
  const [selectedTool, setSelectedTool] = useState(null);

  const handleSelect = (tool) => {
    setSelectedTool(selectedTool === tool ? null : tool);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
      <motion.div
        onClick={() => handleSelect('phonetor')}
        className="cursor-pointer bg-gradient-to-r from-blue-500 to-red-600 p-6 rounded-lg shadow-lg flex items-center justify-center space-x-4 hover:from-blue-600 hover:to-blue-700"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <DeviceMobileIcon className="h-10 w-10 text-green-300" />
        <span className="text-white text-lg font-bold">Phonetor</span>
      </motion.div>

      <motion.div
        onClick={() => handleSelect('validator')}
        className="cursor-pointer bg-gradient-to-r from-yellow-500 to-yellow-600 p-6 rounded-lg shadow-lg flex items-center justify-center space-x-4 hover:from-yellow-600 hover:to-yellow-700"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <ClipboardCheckIcon className="h-10 w-10 text-yellow-300" />
        <span className="text-white text-lg font-bold">Validator</span>
      </motion.div>

      <AnimatePresence>
        {selectedTool === 'phonetor' && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="col-span-1 md:col-span-2 bg-gray-900 text-white p-6 rounded-lg shadow-lg mt-4"
          >
            <h3 className="text-2xl font-bold">Phonetor</h3>
            <p className="mt-4 text-lg">
              Phonetor is our flagship application, engineered to deliver SMS with unparalleled security through both SMTP and API calls. It’s not just a tool—it’s a fortress of communication, designed to protect and ensure the delivery of your most important messages. With Phonetor, you're using the pinnacle of SMS technology.
            </p>
            <LightningBoltIcon className="h-8 w-8 text-yellow-400 mt-4 animate-pulse" />
          </motion.div>
        )}
        {selectedTool === 'validator' && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="col-span-1 md:col-span-2 bg-gray-900 text-white p-6 rounded-lg shadow-lg mt-4"
          >
            <h3 className="text-2xl font-bold">Validator</h3>
            <p className="mt-4 text-lg">
              The Validator is more than just a tool—it's a revelation. It grants you access to the most detailed and accurate information about any mobile number, making it the god of mobile data verification. It stands unrivaled, providing data that is as precise as it is powerful, making it a true titan in its domain.
            </p>
            <ShieldCheckIcon className="h-8 w-8 text-green-400 mt-4 animate-bounce" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuickAccess;
