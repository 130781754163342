import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import FileUploader from './FileUploader';
import Validator from './Validator';
import PhoneTable from './PhoneTable';
import HowToUse from '../../../config/HowToUse';
import { useAuth } from '../../auth/authContext';

const ValidatorModal = ({ isOpen, onClose }) => {
  const [uploadedNumbers, setUploadedNumbers] = useState([]);
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [isHowToUseOpen, setIsHowToUseOpen] = useState(false);

  const { currentUser } = useAuth();

  const handleFileUpload = (numbers) => {
    setUploadedNumbers(numbers);
  };

  const handleValidatedNumbers = (newValidatedNumbers) => {
    setFilteredNumbers(newValidatedNumbers);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
        <Dialog.Panel className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-11/12 max-w-8xl h-5/6 max-h-screen relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-white"
          >
            <XIcon className="h-6 w-6" />
          </button>

          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-semibold">Phone Number Validator</Dialog.Title>
            <button
              onClick={() => setIsHowToUseOpen(true)}
              className="flex items-center space-x-2 bg-red-500 hover:bg-ourple-600 text-white py-2 px-4 rounded-lg"
            >
              <QuestionMarkCircleIcon className="h-5 w-5" />
              <span>How to Use</span>
            </button>
          </div>

          <div className="flex h-full space-x-6">
            {/* Left Side - File Uploader and Validator */}
            <div className="w-1/3 space-y-6">
              <FileUploader onFileUpload={handleFileUpload} />

              {uploadedNumbers.length > 0 && currentUser && (
                <Validator 
                  phoneNumbers={uploadedNumbers} 
                  onValidated={handleValidatedNumbers}
                  usedBy={currentUser.uid}
                />
              )}
            </div>

            {/* Right Side - Phone Table */}
            <div className="w-2/3 overflow-y-auto">
              <PhoneTable filteredNumbers={filteredNumbers} />
            </div>
          </div>
        </Dialog.Panel>
      </div>

      {/* How to Use Modal */}
      <HowToUse isOpen={isHowToUseOpen} onClose={() => setIsHowToUseOpen(false)} />
    </Dialog>
  );
};

export default ValidatorModal;
