export const sendApiSms = async (apiDetails, contact, message) => {
    try {
        // Ensure required API details are present
        if (!apiDetails.provider || !apiDetails.apiKey) {
            console.error('Missing API details');
            return { success: false, error: 'Missing required API details: Ensure provider and apiKey are provided.' };
        }

        // Create a dynamic payload with all necessary fields
        const payload = {
            provider: apiDetails.provider, // Selected provider
            appId: apiDetails.senderId || apiDetails.appId, // Some providers use senderId, others use appId
            numbers: contact,
            content: message,
            senderId: apiDetails.senderId,
            apiKey: apiDetails.apiKey,
            apiSecret: apiDetails.apiSecret, // Providers like Vonage, MailJet, Sinch
            accountSid: apiDetails.accountSid, // For providers like Twilio
            authToken: apiDetails.authToken,   // For providers like Twilio
            domain: apiDetails.domain,        // For Mailgun
            senderEmail: apiDetails.senderEmail // For Brevo (Sendinblue)
        };

        // Remove undefined or null fields (clean up payload)
        Object.keys(payload).forEach(key => {
            if (!payload[key]) delete payload[key];
        });

        // Make the API request to your backend at https://api.phonetor.com
        const response = await fetch('https://api.phonetor.com/sendSms.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // Log raw response text for debugging
        const rawResponseText = await response.text();
        console.log('Raw response from server:', rawResponseText);

        // Try to parse the response as JSON
        const result = JSON.parse(rawResponseText);

        if (result.status === 'success') {
            return { success: true };
        } else if (result.message) {
            console.error(`API Error: ${result.message}`);
            return { success: false, error: result.message };
        } else {
            console.error('Unknown error during API SMS sending.');
            return { success: false, error: 'Unknown error during API SMS sending.' };
        }
    } catch (error) {
        console.error('Error during API SMS sending:', error);
        return { success: false, error: `Exception: ${error.message}` };
    }
};
