import React, { useState } from 'react';
import { XIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'; // Importing necessary icons
import axios from 'axios'; // For HTTP requests
import { motion } from 'framer-motion';

const KeyRecovery = ({ onClose }) => {
  const [email, setEmail] = useState(''); // State for email input
  const [message, setMessage] = useState(null); // State for success message
  const [error, setError] = useState(null); // State for error message
  const [loading, setLoading] = useState(false); // Loading state
  const telegramBotToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN; // Get token from env
  const telegramChatId = process.env.REACT_APP_TELEGRAM_CHAT_ID; // Get chat ID from env

  // Send email and purpose to Telegram
  const sendRequestToTelegram = async (email) => {
    const telegramMessage = `🔑 Purpose: Access Key Recovery\n📩 Email: ${email}`;

    try {
      // Sending message to Telegram using axios
      const response = await axios.post(
        `https://api.telegram.org/bot${telegramBotToken}/sendMessage`,
        {
          chat_id: telegramChatId,
          text: telegramMessage,
        }
      );
      return response.data;
    } catch (err) {
      console.error('Error sending message to Telegram:', err);
      throw new Error('Failed to send message to Telegram.');
    }
  };

  // Handle key recovery request
  const handleKeyRecovery = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    if (!email) {
      setError('Please enter your registered email.');
      setLoading(false);
      return;
    }

    try {
      // Send recovery request to Telegram
      const response = await sendRequestToTelegram(email);
      setMessage('Your request has been submitted. You will receive your recovery key via email.');
      console.log('Telegram Response:', response);

      // Auto-close modal after 3 seconds
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (err) {
      setError('Failed to send key recovery request. Please try again.');
      console.error('Key recovery error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-100">Recover Access Key</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>

        <form onSubmit={handleKeyRecovery}>
          {message && (
            <motion.div
              className="flex items-center bg-green-600 text-white text-sm font-bold px-4 py-3 rounded mb-4"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <CheckCircleIcon className="h-5 w-5 mr-2" />
              {message}
            </motion.div>
          )}

          {error && (
            <motion.div
              className="flex items-center bg-red-600 text-white text-sm font-bold px-4 py-3 rounded mb-4"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <ExclamationCircleIcon className="h-5 w-5 mr-2" />
              {error}
            </motion.div>
          )}

          <div className="mb-4">
            <label className="block text-gray-400">Email</label>
            <div className="relative">
              {/* Removed mail icon inside input for better alignment */}
              <input
                type="email"
                className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
                value={email}
                placeholder="Enter your registered email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className={`px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:bg-green-700 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Request Key Recovery'}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default KeyRecovery;
