import React from 'react';
import { FiMail, FiSettings, FiCheckSquare, FiMessageCircle } from 'react-icons/fi';
import { motion } from 'framer-motion';

const MobileSettingsNav = ({ activeTab, setActiveTab }) => {
  
  const tabs = [
    { label: 'SMTP', icon: <FiMail className="h-5 w-5" />, value: 'smtp', color: 'bg-blue-500' },
    { label: 'SMS API', icon: <FiSettings className="h-5 w-5" />, value: 'smsApi', color: 'bg-green-500' },
    { label: 'Validator', icon: <FiCheckSquare className="h-5 w-5" />, value: 'validator', color: 'bg-yellow-500' },
    { label: 'Telegram Bot', icon: <FiMessageCircle className="h-5 w-5" />, value: 'telegram', color: 'bg-purple-500' },
  ];

  return (
    <nav className="grid grid-cols-2 gap-2 bg-gray-800 rounded-lg p-2">
      {tabs.map((tab) => (
        <motion.button
          key={tab.value}
          onClick={() => {
            setActiveTab(tab.value);
          }}
          className={`flex flex-col items-center space-y-1 py-2 rounded-lg transition-colors duration-200 ${
            activeTab === tab.value ? `${tab.color} text-white` : 'bg-gray-600 text-gray-400 hover:bg-gray-700 hover:text-white'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {tab.icon}
          <span className="text-xs">{tab.label}</span>
        </motion.button>
      ))}
    </nav>
  );
};

export default MobileSettingsNav;
