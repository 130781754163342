import React, { useState } from 'react';
import { HomeIcon, CogIcon, LogoutIcon, DeviceMobileIcon, ClipboardCheckIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { signOut } from 'firebase/auth';
import { auth } from '../../../../config/firebaseConfig';
import MobileValidatorModal from './MobileValidatorModal';
import MobileSettingsModal from './MobileSettingsModal';
import PhonetorModal from '../../phonetor/PhonetorModal';

const MobileHeader = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPhonetorOpen, setIsPhonetorOpen] = useState(false);
  const [isValidatorOpen, setIsValidatorOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsLoggingOut(true);

    try {
      // Perform any necessary cleanup before logging out

      // Wait for a moment before logging out (can be adjusted)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Sign out from Firebase auth
      await signOut(auth);

      // Redirect to home or login page
      navigate('/');
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally show a user-friendly error message
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <>
      <nav className="fixed inset-x-0 bottom-0 bg-gray-800 text-white flex justify-between items-center p-2 z-50 shadow-lg">
        <motion.button
          onClick={() => navigate('/dashboard')}
          className="flex flex-col items-center space-y-1 hover:text-gray-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <HomeIcon className="h-6 w-6 text-blue-500" />
          <span className="text-xs">Home</span>
        </motion.button>
        <motion.button
          onClick={() => setIsPhonetorOpen(true)}
          className="flex flex-col items-center space-y-1 hover:text-gray-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <DeviceMobileIcon className="h-6 w-6 text-green-500" />
          <span className="text-xs">Phonetor</span>
        </motion.button>
        <motion.button
          onClick={() => setIsValidatorOpen(true)}
          className="flex flex-col items-center space-y-1 hover:text-gray-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <ClipboardCheckIcon className="h-6 w-6 text-yellow-500" />
          <span className="text-xs">Validator</span>
        </motion.button>
        <motion.button
          onClick={() => setIsSettingsOpen(true)}
          className="flex flex-col items-center space-y-1 hover:text-gray-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <CogIcon className="h-6 w-6 text-purple-500" />
          <span className="text-xs">Settings</span>
        </motion.button>
        <motion.button
          onClick={handleLogout}
          className="flex flex-col items-center space-y-1 hover:text-gray-300 focus:outline-none"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {isLoggingOut ? (
            <svg
              className="animate-spin h-6 w-6 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
              ></path>
            </svg>
          ) : (
            <>
              <LogoutIcon className="h-6 w-6 text-red-500" />
              <span className="text-xs">Logout</span>
            </>
          )}
        </motion.button>
      </nav>

      {/* Modals */}
      <MobileSettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
      <PhonetorModal isOpen={isPhonetorOpen} onClose={() => setIsPhonetorOpen(false)} />
      <MobileValidatorModal isOpen={isValidatorOpen} onClose={() => setIsValidatorOpen(false)} />
    </>
  );
};

export default MobileHeader;
