import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../../config/firebaseConfig';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDo, getDocs, where, collection, query } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authKeyValid, setAuthKeyValid] = useState(false);
  const [authKeyRequired, setAuthKeyRequired] = useState(false);
  const [keyRecoveryMode, setKeyRecoveryMode] = useState(false); // NEW: flag for key recovery mode
  const navigate = useNavigate();
  const location = useLocation();

  // Check if Auth Key and Fingerprint are stored in localStorage
  const checkLocalStorage = useCallback(() => {
    const storedKey = localStorage.getItem('authKey');
    const storedFingerprint = localStorage.getItem('browserFingerprint');

    if (storedKey && storedFingerprint) {
      setAuthKeyValid(true);
      return true;
    }
    return false;
  }, []);

  // Store Auth Key and Fingerprint in localStorage
  const storeInLocalStorage = useCallback((key, fingerprint) => {
    localStorage.setItem('authKey', key);
    localStorage.setItem('browserFingerprint', fingerprint);
    setAuthKeyValid(true);
  }, []);

// Check auth key in AccessKeys collection
const checkAuthKeyInFirebase = useCallback(async (key) => {
  const accessKeyQuery = query(
    collection(db, 'AccessKeys'),
    where('key', '==', key)
  );
  const querySnapshot = await getDocs(accessKeyQuery);

  if (!querySnapshot.empty) {
    // If auth key is found in Firestore, return true
    return true;
  }
  return false;
}, []);

const checkInFirebase = useCallback(async (uid, fingerprint, key) => {
  const userRef = doc(db, 'Users', uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists() && userDoc.data().browserFingerprint === fingerprint) {
    // Check if the auth key exists in the AccessKeys collection
    const keyValid = await checkAuthKeyInFirebase(key);
    if (keyValid) {
      setAuthKeyValid(true);
      return true;
    }
  }
  return false;
}, [checkAuthKeyInFirebase]);

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    setLoading(true);
    setCurrentUser(user);

    const publicRoutes = ['/', '/about', '/contact', '/admin/add-key'];

    if (user) {
      const fingerprint = localStorage.getItem('browserFingerprint');
      const storedKey = localStorage.getItem('authKey');

      if (checkLocalStorage()) {
        if (!keyRecoveryMode) {
          navigate('/dashboard');
        }
      } else if (fingerprint && storedKey && (await checkInFirebase(user.uid, fingerprint, storedKey))) {
        if (!keyRecoveryMode) {
          navigate('/dashboard');
        }
      } else {
        setAuthKeyRequired(true);
      }
    } else if (!publicRoutes.includes(location.pathname)) {
      navigate('/');
    }

    setLoading(false);
  });

  return () => unsubscribe();
}, [navigate, location.pathname, checkLocalStorage, checkInFirebase, keyRecoveryMode]);



  const login = async (email, password, isKeyRecovery = false) => {
    if (isKeyRecovery) {
      setKeyRecoveryMode(true); // Set recovery mode when logging in for key recovery
    }
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
    setAuthKeyValid(false);
    localStorage.removeItem('authKey');
    localStorage.removeItem('browserFingerprint');
    setKeyRecoveryMode(false); // Reset recovery mode on logout
    navigate('/');
  };

  const value = {
    currentUser,
    loading,
    login,
    logout,
    storeInLocalStorage, // Store Auth Key and Fingerprint in localStorage
    authKeyValid,
    authKeyRequired,
    keyRecoveryMode, // Expose recovery mode state
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
