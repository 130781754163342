import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Phonetor from './Phonetor';
import PhonetorSettings from './PhonetorSettings';
import TestSms from './TestSms'; // Import TestSms component

const PhonetorModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('phonetor');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-6xl overflow-y-auto max-h-screen"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between mb-6">
          <button
            onClick={onClose}
            className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-lg transition-transform duration-200 hover:scale-105"
          >
            Close
          </button>
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab('phonetor')}
              className={`px-6 py-3 rounded-lg text-lg font-semibold transition-colors duration-300 ${
                activeTab === 'phonetor' ? 'bg-yellow-500 text-gray-900' : 'bg-gray-700 text-white hover:bg-gray-600'
              }`}
            >
              Phonetor
            </button>
            <button
              onClick={() => setActiveTab('settings')}
              className={`px-6 py-3 rounded-lg text-lg font-semibold transition-colors duration-300 ${
                activeTab === 'settings' ? 'bg-yellow-500 text-gray-900' : 'bg-gray-700 text-white hover:bg-gray-600'
              }`}
            >
              Phonetor Settings
            </button>
            <button
              onClick={() => setActiveTab('testsms')}
              className={`px-6 py-3 rounded-lg text-lg font-semibold transition-colors duration-300 ${
                activeTab === 'testsms' ? 'bg-yellow-500 text-gray-900' : 'bg-gray-700 text-white hover:bg-gray-600'
              }`}
            >
              Test SMS
            </button>
          </div>
        </div>

        <div className="mb-6">
          {activeTab === 'phonetor' && <Phonetor />}
          {activeTab === 'settings' && <PhonetorSettings />}
          {activeTab === 'testsms' && <TestSms />}
        </div>
      </motion.div>
    </div>
  );
};

export default PhonetorModal;
