import CryptoJS from 'crypto-js';
export const smsProviders = {
  telnyx: {
    label: 'Telnyx',
    endpoint: 'https://api.telnyx.com/v2/messages',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'phoneNumber', placeholder: 'Phone Number' }
    ],
    buildPayload: (data) => ({
      to: data.phoneNumber,
      text: data.message,
      from: data.senderId || null,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Bearer ${data.apiKey}`,
    }),
    formatPhoneNumber: (phoneNumber) => `+1${phoneNumber}` // Telnyx expects + country code
  },
  mailjet: {
    label: 'MailJet',
    endpoint: 'https://api.mailjet.com/v4/sms-send',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'apiSecret', placeholder: 'API Secret' },
      { name: 'phoneNumber', placeholder: 'Phone Number' }
    ],
    buildPayload: (data) => ({
      To: data.phoneNumber,
      From: data.senderId || 'MyApp',
      Text: data.message,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Basic ${btoa(data.apiKey + ':' + data.apiSecret)}`,
    }),
    formatPhoneNumber: (phoneNumber) => `${phoneNumber}` // MailJet expects plain number
  },
  twilio: {
    label: 'Twilio',
    endpoint: 'https://api.twilio.com/2010-04-01/Accounts/{accountSid}/Messages.json',
    fields: [
      { name: 'accountSid', placeholder: 'Account SID' },
      { name: 'authToken', placeholder: 'Auth Token' },
      { name: 'phoneNumber', placeholder: 'Phone Number' }
    ],
    buildPayload: (data) => ({
      To: data.phoneNumber,
      From: data.senderId,
      Body: data.message,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Basic ${btoa(data.accountSid + ':' + data.authToken)}`,
    }),
    formatPhoneNumber: (phoneNumber) => `+1${phoneNumber}` // Twilio expects + country code
  },
  vonage: {
    label: 'Vonage',
    endpoint: 'https://rest.nexmo.com/sms/json',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'apiSecret', placeholder: 'API Secret' },
      { name: 'phoneNumber', placeholder: 'Phone Number' }
    ],
    buildPayload: (data) => ({
      api_key: data.apiKey,
      api_secret: data.apiSecret,
      to: data.phoneNumber,
      from: data.senderId || 'Vonage',
      text: data.message,
    }),
    buildHeaders: () => ({}), // No additional headers required
    formatPhoneNumber: (phoneNumber) => `1${phoneNumber}` // Vonage expects number without +
  },
  ycloud: {
    label: 'YCloud',
    endpoint: 'https://api.ycloud.com/sms/v1/messages',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'senderId', placeholder: 'Sender ID' }
    ],
    buildPayload: (data) => ({
      to: data.phoneNumber,
      content: data.message,
      senderId: data.senderId,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Bearer ${data.apiKey}`,
    }),
    formatPhoneNumber: (phoneNumber) => `+1${phoneNumber}` // YCloud expects + country code
  },
  sinch: {
    label: 'Sinch',
    endpoint: 'https://api.sinch.com/xms/v1/{projectId}/batches',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'apiSecret', placeholder: 'API Secret' },
      { name: 'phoneNumber', placeholder: 'Phone Number' }
    ],
    buildPayload: (data) => ({
      to: [data.phoneNumber],
      body: data.message,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Basic ${btoa(data.apiKey + ':' + data.apiSecret)}`,
    }),
    formatPhoneNumber: (phoneNumber) => `1${phoneNumber}` // Sinch expects number without +
  },
  onbuka: {
    label: 'Onbuka',
    endpoint: 'https://api.onbuka.com/v3/sendSms',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'apiSecret', placeholder: 'API Secret' },
      { name: 'appId', placeholder: 'App ID' }
    ],
    buildPayload: (data) => ({
      appId: data.appId,
      numbers: data.phoneNumber,
      content: data.message,
      senderId: data.senderId,
    }),
    buildHeaders: (data) => ({
      'Content-Type': 'application/json',
      'Sign': `${CryptoJS.MD5(data.apiKey + data.apiSecret + Date.now())}`,
      'Api-Key': data.apiKey,
      'Timestamp': Date.now(),
    }),
    formatPhoneNumber: (phoneNumber) => `1${phoneNumber}` // Onbuka expects number without +
  },
  mailgun: {
    label: 'Mailgun',
    endpoint: 'https://api.mailgun.net/v3/{domain}/messages',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'domain', placeholder: 'Domain' }
    ],
    buildPayload: (data) => ({
      from: `Mailgun <mailgun@${data.domain}>`,
      to: data.phoneNumber,
      text: data.message,
    }),
    buildHeaders: (data) => ({
      'Authorization': `Basic ${btoa('api:' + data.apiKey)}`,
    }),
    formatPhoneNumber: (phoneNumber) => `${phoneNumber}` // Mailgun expects plain number
  },
  brevo_sendinblue: {
    label: 'Brevo(SendinBlue)',
    endpoint: 'https://api.brevo.com/v3/smtp/email',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'senderEmail', placeholder: 'Sender Email' }
    ],
    buildPayload: (data) => ({
      sender: { email: data.senderEmail },
      to: [{ email: data.phoneNumber }],
      subject: 'SMS Message',
      htmlContent: data.message,
    }),
    buildHeaders: (data) => ({
      'api-key': data.apiKey,
    }),
    formatPhoneNumber: (phoneNumber) => `${phoneNumber}` // Brevo expects plain number
  },
  brevo_sms_api: {
    label: 'Brevo - SMS API',
    endpoint: 'https://api.brevo.com/v3/transactionalSMS/sms',
    fields: [
      { name: 'apiKey', placeholder: 'API Key' },
      { name: 'senderId', placeholder: 'Sender ID' }
    ],
    buildPayload: (data) => ({
      sender: data.senderId,
      recipient: data.phoneNumber,
      content: data.message,
    }),
    buildHeaders: (data) => ({
      'api-key': data.apiKey,
    }),
    formatPhoneNumber: (phoneNumber) => `${phoneNumber}` // Brevo SMS API expects plain number
  }
};
