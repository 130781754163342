import React, { useState, useEffect } from 'react';
import { InformationCircleIcon, ClockIcon, BellIcon } from '@heroicons/react/outline';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import NotificationModal from '../subheader/NotificationModal';
import { useAuth } from '../../../auth/authContext';

const MobileSubheader = () => {
  const { currentUser } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchExpiryDate = async () => {
      if (currentUser) {
        const keyDocRef = query(collection(db, 'AccessKeys'), where('usedBy', '==', currentUser.uid));
        const keySnapshot = await getDocs(keyDocRef);
        
        keySnapshot.forEach((doc) => {
          const data = doc.data();
          const expiresAt = data.expiresAt.toDate(); // Convert Firestore timestamp to JS Date

          const intervalId = setInterval(() => {
            const now = new Date();
            const timeDiff = expiresAt - now;

            if (timeDiff <= 0) {
              clearInterval(intervalId);
              setTimeRemaining('Expired');
            } else {
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
              setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            }
          }, 1000);

          return () => clearInterval(intervalId); // Clean up the interval on unmount
        });
      }
    };

    const fetchNotifications = async () => {
      try {
        const notificationsRef = collection(db, 'Notifications');
        const notificationsSnapshot = await getDocs(notificationsRef);

        const fetchedNotifications = [];
        notificationsSnapshot.forEach((doc) => {
          fetchedNotifications.push(doc.data().message); // Assuming the notification has a 'message' field
        });

        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]); // Set to empty array to avoid undefined error
      }
    };

    fetchExpiryDate();
    fetchNotifications();
  }, [currentUser]);

  return (
    <div className="fixed top-0 left-0 w-full bg-gray-800 bg-opacity-95 text-white flex justify-between items-center px-4 py-2 shadow-2xl z-50 border-b border-gray-700">
      <div className="flex items-center space-x-2">
        <ClockIcon className="h-5 w-5 text-yellow-400" />
        <span className="text-sm font-semibold">Key Expires In:</span>
        <span className="text-sm text-blue-400">{timeRemaining}</span>
      </div>
      <button onClick={() => setShowModal(true)} className="flex items-center space-x-2">
        <BellIcon className="h-5 w-5 text-yellow-400 hover:text-yellow-300 transition-all duration-200 ease-in-out" />
      </button>
      {showModal && (
        <NotificationModal notifications={notifications} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default MobileSubheader;
