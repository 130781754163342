// src/components/users/navs/mobile/MobileValidatorModal.js

import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import FileUploader from '../../validator/FileUploader';
import Validator from '../../validator/Validator';
import PhoneTable from '../../validator/PhoneTable';
import { useAuth } from '../../../auth/authContext'; // Import the useAuth hook
import MobilePhoneTable from './MobilePhoneTable';

const MobileValidatorModal = ({ isOpen, onClose }) => {
  const [uploadedNumbers, setUploadedNumbers] = useState([]);
  const [filteredNumbers, setFilteredNumbers] = useState([]);

  const { currentUser } = useAuth(); // Get the current user from AuthContext

  const handleFileUpload = (numbers) => {
    setUploadedNumbers(numbers);
  };

  const handleValidatedNumbers = (newValidatedNumbers) => {
    setFilteredNumbers(newValidatedNumbers);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
        <Dialog.Panel className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-11/12 max-w-md h-5/6 max-h-screen relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-white"
          >
            <XIcon className="h-6 w-6" />
          </button>

          <Dialog.Title className="text-lg font-semibold mb-4">Phone Number Validator</Dialog.Title>

          <div className="flex flex-col h-full space-y-4">
            {/* File Uploader and Validator */}
            <div className="space-y-4 overflow-y-auto">
              <FileUploader onFileUpload={handleFileUpload} />

              {uploadedNumbers.length > 0 && currentUser && (
                <Validator 
                  phoneNumbers={uploadedNumbers} 
                  onValidated={handleValidatedNumbers}
                  usedBy={currentUser.uid} // Pass the user's UID as usedBy
                />
              )}
            </div>

            {/* Phone Table */}
            <div className="overflow-y-auto">
            <MobilePhoneTable filteredNumbers={filteredNumbers} />            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default MobileValidatorModal;
