import React, { useState } from 'react';
import Papa from 'papaparse';
import { UploadIcon, PaperClipIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

const FileUploader = ({ onFileUpload }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [countryCode, setCountryCode] = useState(''); // New state for country code

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      processFile(selectedFile);
    }
  };

  const processFile = (file) => {
    setIsProcessing(true);
    setMessage('Processing file...');
    setProgress(0);

    const fileType = file.type;
    const reader = new FileReader();

    reader.onprogress = (e) => {
      if (e.lengthComputable) {
        setProgress((e.loaded / e.total) * 100);
      }
    };

    reader.onload = (e) => {
      const content = e.target.result;
      if (fileType.includes('csv')) {
        Papa.parse(content, {
          header: false,
          complete: (results) => {
            handlePhoneNumbers(results.data.flat());
            setProgress(100);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            setMessage('Error parsing file');
            setIsProcessing(false);
          },
        });
      } else if (fileType.includes('text') || fileType.includes('plain')) {
        handlePhoneNumbers(content.split('\n').map(line => line.trim()));
        setProgress(100);
      } else {
        setMessage('Unsupported file type');
        setIsProcessing(false);
      }
    };

    reader.readAsText(file);
  };

  const handlePhoneNumbers = (data) => {
    const numbers = data.filter(line => /^\d{10,15}$/.test(line)); // Basic validation
    setPhoneNumbers(numbers);

    if (numbers.length > 0) {
      setShowModal(true);
    } else {
      setMessage('No valid phone numbers found');
      setIsProcessing(false);
    }
  };

  const applyCountryCode = () => {
    let formattedNumbers = phoneNumbers;

    if (countryCode) {
      formattedNumbers = phoneNumbers.map(number => {
        // Only add the country code if it's not already present
        return number.startsWith(countryCode) ? number : `${countryCode}${number}`;
      });
    }

    setPhoneNumbers(formattedNumbers);
    setShowModal(false);
    setMessage('Phone numbers processed');
    setIsProcessing(false);

    if (onFileUpload) {
      onFileUpload(formattedNumbers);
    }
  };

  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg space-y-4">
      <h2 className="text-2xl font-semibold flex items-center space-x-2">
        <UploadIcon className="h-6 w-6 text-blue-500" />
        <span>Upload Phone Numbers</span>
      </h2>
      
      <input
        type="file"
        accept=".txt,.csv"
        onChange={handleFileChange}
        className="bg-gray-900 text-white p-2 rounded-lg border border-gray-600 w-full"
      />
      
      {message && <p className={`text-center ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
      
      {showModal && (
        <motion.div
          className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-lg font-semibold flex items-center space-x-2">
              <PaperClipIcon className="h-5 w-5 text-gray-400" />
              <span>Add Country Code</span>
            </h3>
            
            <p className="mt-2">You can add a country code to the phone numbers. If you leave this blank, the numbers will remain as they are.</p>
            
            <div className="mt-4">
              <label htmlFor="countryCode" className="block text-sm font-medium text-gray-300">
                Enter Country Code (e.g., +1):
              </label>
              <input
                id="countryCode"
                type="text"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="mt-1 block w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                placeholder="Leave blank to keep original numbers"
              />
            </div>

            <div className="mt-4 flex space-x-4">
              <button
                onClick={applyCountryCode}
                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg"
              >
                Apply
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </motion.div>
      )}
      
      <div className="mt-4">
        <p className="text-sm text-gray-400">Number of valid phone numbers: {phoneNumbers.length}</p>
        <progress value={progress} max="100" className="w-full bg-gray-600 mt-2" />
      </div>
    </div>
  );
};

export default FileUploader;
