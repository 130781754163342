// src/config/pricing.js

export const pricingPlans = [
  {
    plan: "Starter",
    name: "Regular SMS Sender",
    description: "Access to SMS Sender Via API, 500 SMS",
    prices: {
      "1 month": "$70",
    },
    allocation: {
      sms: 500,
      validation: 500,
    },
  },
  {
    plan: "Ambitious",
    name: "Email to SMS Using SMTP",
    description: "Access to SMS Sender Via SMTP & API, 1,000 SMS  & 1,000 API",
    prices: {
      "1 month": "$120",
    },
    allocation: {
      sms: 2000,
      validation: 2000,
    },
  },
  {
    plan: "Complete Access",
    name: "Complete Access",
    description: "SMS API Sender + Email2SMS + PhoneValidator & Converter, 3,000 SMS.",
    prices: {
      "1 month": "$220 + extra 6 days",
    },
    allocation: {
      sms: 3000,
      validation: 3000,
    },
  },
];
