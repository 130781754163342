import React, { useState } from 'react';
import useFetchApiKeys from './useFetchApiKeys';
import validatePhoneNumbers from './validatePhoneNumbers';
import FilterCarrier from './FilterCarrier';
import { saveAs } from 'file-saver';
import { carrierKeywords } from '../../../config/carrierConfig';
import { checkAndDeductValidations } from '../../../config/Rules'; 

const Validator = ({ phoneNumbers, onValidated, usedBy }) => {
    const [validatedNumbers, setValidatedNumbers] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [progress, setProgress] = useState(0);
    const [verifiedCount, setVerifiedCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterCarrier, setFilterCarrier] = useState('');
    const [selectedService, setSelectedService] = useState('');

    const apiKeys = useFetchApiKeys();

    const handleValidate = async () => {

        if (apiKeys.length === 0) {
            console.error("No API keys available for validation.");
            return;
        }

        const numberOfPhoneNumbers = phoneNumbers.length;


        try {
            const canProceed = await checkAndDeductValidations(usedBy, numberOfPhoneNumbers);
            if (!canProceed) {
                console.warn("No available validations or failed to deduct validations.");
                return;
            }
        } catch (error) {
            console.error("Error checking or deducting validations:", error);
            return;
        }

        setValidatedNumbers([]);
        setCarriers([]);
        setVerifiedCount(0);
        setProgress(0);
        setLoading(true);

        const selectedApiKey = apiKeys.find(api => api.service === selectedService)?.key;

        if (!selectedApiKey) {
            console.error(`No API key found for the selected service: ${selectedService}`);
            setLoading(false);
            return;
        }

        try {
            const results = await validatePhoneNumbers(
                phoneNumbers,
                [selectedApiKey],
                carriers,
                setCarriers,
                setProgress,
                setValidatedNumbers,
                setVerifiedCount,
                selectedService 
            );
            setValidatedNumbers(results);

            if (onValidated) {
                onValidated(results); // Ensure results are passed to onValidated
            }

            const uniqueCarriers = [...new Set(results.map(phone => {
                if (!phone.carrierEmail) {
                    console.warn(`Carrier email is undefined for phone number: ${phone.number}`);
                    return 'Unknown';
                }
                const emailSuffix = Object.values(carrierKeywords).find(suffix => phone.carrierEmail.includes(suffix));
                return emailSuffix ? Object.keys(carrierKeywords).find(key => carrierKeywords[key] === emailSuffix) : 'Unknown';
            }))];
            setCarriers(uniqueCarriers);

        } catch (error) {
            console.error("Validation failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (selectedCarrier) => {
        let filteredNumbers;
        if (selectedCarrier === "") {
            filteredNumbers = validatedNumbers;
        } else {
            filteredNumbers = validatedNumbers.filter(phone =>
                phone.carrierEmail && phone.carrierEmail.includes(carrierKeywords[selectedCarrier])
            );
        }
        setFilterCarrier(selectedCarrier);

        if (onValidated) {
            onValidated(filteredNumbers);
        }
    };

    const handleCopyToClipboard = () => {
        const textToCopy = validatedNumbers.map(phone => phone.carrierEmail.replace(/^\+1/, '')).join("\n");
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Carrier emails copied to clipboard!');
        }).catch(error => {
            console.error("Failed to copy to clipboard:", error);
        });
    };

    const handleDownload = () => {
        const carrierEmails = validatedNumbers
            .map(phone => phone.carrierEmail ? phone.carrierEmail.replace(/^\+1/, '') : null)
            .filter(email => email !== null);

        if (carrierEmails.length === 0) {
            alert('No valid carrier emails to download.');
            return;
        }

        const fileName = filterCarrier
            ? `${filterCarrier.toUpperCase()}_Filtered.csv`
            : 'filtered_results.csv';

        const csvData = carrierEmails.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName);
    };

    return (
        <div className="bg-gray-800 text-white p-3 rounded-lg shadow-lg space-y-2 text-xs">
            <div className="font-medium text-white">
                Total Phone Numbers: {phoneNumbers.length}
            </div>
    
            <div className="flex flex-wrap space-x-2">
                <div className="flex-1">
                    <label className="block text-white text-sm font-medium mb-1">
                        Select API Service:
                    </label>
                    <select
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    >
                        <option value="">Select Service</option>
                        {apiKeys.map((api) => (
                            <option key={api.service} value={api.service}>
                                {api.service}
                            </option>
                        ))}
                    </select>
                </div>
    
                <div className="flex-1">
                    <button
                        onClick={handleValidate}
                        className="w-full py-2 mt-6 font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
                        disabled={loading || !selectedService}
                    >
                        {loading ? 'Validating...' : 'Start Validation'}
                    </button>
                </div>
            </div>
    
            <div className="flex flex-wrap space-x-2">
                <div className="flex-1">
                    <div className="font-medium text-white mt-1">
                        Verified: {verifiedCount} / {phoneNumbers.length}
                    </div>
                </div>
                <div className="flex-1">
                    <FilterCarrier carriers={carriers} filterCarrier={filterCarrier} handleFilterChange={handleFilterChange} />
                </div>
            </div>
    
            <div className="flex flex-wrap space-x-2">
                <div className="flex-1">
                    <button
                        onClick={handleCopyToClipboard}
                        className="w-full py-2 font-medium text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none"
                        disabled={loading || !validatedNumbers.length}
                    >
                        Copy Filtered Emails to Clipboard
                    </button>
                </div>
                <div className="flex-1">
                    <button
                        onClick={handleDownload}
                        className="w-full py-2 font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
                        disabled={loading || !validatedNumbers.length}
                    >
                        Download Filtered Emails
                    </button>
                </div>
            </div>
    
            {loading && (
                <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                    <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${progress}%` }}></div>
                </div>
            )}
        </div>
    );
};

export default Validator;
