// src/config/QuestionList.js
const QuestionList = [
  "What was the name of your first pet?",
  "What is your mother's maiden name?",
  "What was your high school mascot?",
  "What is your favorite book?",
  "In what city were you born?",
  "What is the name of your favorite childhood friend?",
  "What was the name of the street you grew up on?",
  "What is your favorite food?",
  "What was your dream job as a child?",
  "What is your father's middle name?",
  "What was the make and model of your first car?",
  "What is the name of your first teacher?",
  "What is the name of your first employer?",
  "What was the name of your first school?",
  "Where did you go on your first vacation?",
  "What is your favorite color?",
  "What is the first concert you attended?",
  "What is the name of your first boyfriend/girlfriend?",
  "What is your favorite movie?",
  "What is your favorite sport?",
  "What is the name of the hospital where you were born?",
  "What is your favorite hobby?",
  "What was the first album you ever bought?",
  "What is the name of your first boss?",
  "What is the first foreign country you visited?",
  "Who was your childhood hero?",
  "What is your favorite animal?",
  "What is the name of your favorite cousin?",
  "What was your childhood nickname?",
  "What was the first video game you played?"
];

export default QuestionList;
