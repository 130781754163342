import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';

const TestSms = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sentMessages, setSentMessages] = useState([]);
  const [smtpAccounts, setSmtpAccounts] = useState([]);
  const [smsAPIs, setSmsAPIs] = useState([]);
  const [selectedSMTP, setSelectedSMTP] = useState('');
  const [selectedSMSAPI, setSelectedSMSAPI] = useState('');
  const [linkEnabled, setLinkEnabled] = useState(false);
  const [useSmtp, setUseSmtp] = useState(false);
  const [messageStatus, setMessageStatus] = useState(null); // To display the status of the message
  const predefinedMessage = 'Alert: This is a test from Phonetor coded by Anon10107 ';
  const predefinedLink = 'https://phonetor.com';

  // Load settings from localStorage on component mount
  useEffect(() => {
    const storedSettings = JSON.parse(localStorage.getItem('phonetorSettings'));

    if (storedSettings) {
      if (storedSettings.smtpDetails) {
        setSmtpAccounts([storedSettings.smtpDetails]);
        setSelectedSMTP(storedSettings.smtpDetails.host);
      }

      if (storedSettings.smsAPIDetails) {
        setSmsAPIs([storedSettings.smsAPIDetails]);
        setSelectedSMSAPI(storedSettings.smsAPIDetails.provider);
      }

      if (storedSettings.command) {
        setUseSmtp(storedSettings.command.useSMTP === '#1');
        setLinkEnabled(storedSettings.command.linkOn === '#1');
      }
    }
  }, []);

  const handleSendSms = async () => {
    if (!phoneNumber) {
      setMessageStatus({ success: false, text: 'Please enter a phone number.' });
      return;
    }

    setIsSending(true);
    setMessageStatus(null); // Reset message status before sending
    const finalMessage = `${predefinedMessage}${linkEnabled ? ` ${predefinedLink}` : ''}`;

    try {
      let result;

      if (useSmtp) {
        // Sending SMS via SMTP
        const smtpDetails = smtpAccounts.find(smtp => smtp.host === selectedSMTP);
        const response = await fetch('https://homeaidrelief.com/sms/smtpSendSms.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            smtpHost: smtpDetails.host,
            smtpPort: smtpDetails.port,
            smtpUser: smtpDetails.user,
            smtpPassword: smtpDetails.password,
            mailFrom: smtpDetails.from,
            fromName: smtpDetails.user,
            messageContent: finalMessage,
            recipients: [phoneNumber],
          }),
        });

        result = await response.json();

        // Log the response for debugging (remove later)

        // Check if the response has the expected structure
        if (!result || typeof result !== 'object') {
          throw new Error('Invalid SMTP response format');
        }

        if (result.status === 'success') {
          setMessageStatus({ success: true, text: `SMS sent to ${phoneNumber} via SMTP: ${finalMessage}` });
        } else {
          setMessageStatus({ success: false, text: `Failed to send SMS via SMTP: ${result.message || 'Unknown error'}` });
        }
      } else {
        // Sending SMS via Onbuka API
        const apiDetails = smsAPIs.find(api => api.provider === selectedSMSAPI);
        const formattedPhoneNumber = `${phoneNumber}`;

        const response = await fetch('https://homeaidrelief.com/sms/sendSms.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            appId: apiDetails.senderId,
            numbers: formattedPhoneNumber,
            content: finalMessage,
            senderId: apiDetails.senderId,
            apiKey: apiDetails.apiKey,
            apiSecret: apiDetails.apiSecret,
          }),
        });

        result = await response.json();

        if (result.status === '0') {
          setMessageStatus({ success: true, text: `SMS sent to ${phoneNumber} via API: ${finalMessage}` });
        } else {
          setMessageStatus({ success: false, text: `Failed to send SMS via API: ${result.reason}` });
        }
      }

      // Update sent messages list
      const newMessage = {
        phoneNumber,
        message: finalMessage,
        timestamp: new Date().toLocaleTimeString(),
        method: useSmtp ? `SMTP (${selectedSMTP})` : `API (${selectedSMSAPI})`,
      };
      setSentMessages([...sentMessages, newMessage]);

    } catch (error) {
      console.error('Error:', error); // Log the error
      setMessageStatus({ success: false, text: `Failed to send SMS: ${error.message}` });
    } finally {
      setIsSending(false);
    }
  };


  return (
    <div className="flex space-x-4">
      {/* Left Side: SMS Test Form */}
      <div className="flex-1 flex flex-col space-y-4">
        <h3 className="text-xl font-semibold">Test SMS</h3>

        {messageStatus && (
          <div className={`p-2 rounded-lg text-white ${messageStatus.success ? 'bg-green-500' : 'bg-red-500'}`}>
            {messageStatus.text}
          </div>
        )}

        <div>
          <label className="block text-gray-400 mb-2">Select SMTP Account</label>
          <select
            value={selectedSMTP}
            onChange={(e) => setSelectedSMTP(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded-lg border border-gray-600 w-full"
            disabled={!useSmtp}
          >
            <option value="">Select SMTP Account</option>
            {smtpAccounts.map((smtp, index) => (
              <option key={index} value={smtp.host}>
                {smtp.host} - {smtp.user}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-400 mb-2">Select SMS API Provider</label>
          <select
            value={selectedSMSAPI}
            onChange={(e) => setSelectedSMSAPI(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded-lg border border-gray-600 w-full"
            disabled={useSmtp}
          >
            <option value="">Select SMS API Provider</option>
            {smsAPIs.map((api, index) => (
              <option key={index} value={api.provider}>
                {api.provider} - {api.apiKey}
              </option>
            ))}
          </select>
        </div>

        <input
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="bg-gray-700 text-white p-2 rounded-lg border border-gray-600"
        />

        <div className="flex items-center space-x-4">
          <label className="mr-4 text-white">{useSmtp ? 'Use SMTP' : 'Use API'}</label>
          <Switch
            checked={useSmtp}
            onChange={setUseSmtp}
            className={`${
              useSmtp ? 'bg-blue-500' : 'bg-gray-700'
            } relative inline-flex items-center h-6 rounded-full w-16 transition-colors duration-200`}
          >
            <span
              className={`${
                useSmtp ? 'translate-x-10' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
            />
          </Switch>

          <label className="mr-4 text-white">{linkEnabled ? 'Link On' : 'Link Off'}</label>
          <Switch
            checked={linkEnabled}
            onChange={setLinkEnabled}
            className={`${
              linkEnabled ? 'bg-green-500' : 'bg-gray-700'
            } relative inline-flex items-center h-6 rounded-full w-16 transition-colors duration-200`}
          >
            <span
              className={`${
                linkEnabled ? 'translate-x-10' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
            />
          </Switch>
        </div>

        <button
          onClick={handleSendSms}
          className={`py-2 px-4 rounded-lg text-white ${isSending ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700'}`}
          disabled={isSending}
        >
          {isSending ? 'Sending...' : 'Send SMS'}
        </button>
      </div>

      {/* Right Side: Sent Messages */}
      <div className="flex-1 flex flex-col space-y-4">
        <h3 className="text-xl font-semibold">Sent Messages</h3>
        <div className="bg-gray-800 p-4 rounded-lg border border-gray-600 h-64 overflow-y-auto">
          {sentMessages.length === 0 ? (
            <p className="text-gray-400">No messages sent yet.</p>
          ) : (
            <ul className="space-y-2">
              {sentMessages.map((msg, index) => (
                <li key={index} className="text-gray-200">
                  <p><strong>To:</strong> {msg.phoneNumber}</p>
                  <p><strong>Message:</strong> {msg.message}</p>
                  <p><strong>Method:</strong> {msg.method}</p>
                  <p className="text-sm text-gray-400"><em>Sent at {msg.timestamp}</em></p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestSms;
