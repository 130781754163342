//PhonetorSender.js

import { sendApiSms } from './ApiEndpoint'; // For API sending
import { sendSmtpSms } from './SmtpEndpoint'; // For SMTP sending
import { smsProviders } from '../../../config/smsProvidersConfig'; // For contact formatting logic
import { CheckIcon, XIcon } from '@heroicons/react/solid'; // Assuming you're using Heroicons for the icons

export const sendMessages = async (contacts, useSmtp, selectedSMSAPI, smtpDetails, message, linkEnabled) => {
  const updatedFeed = [];

  // Retrieve link from localStorage or default
  const storedSettings = JSON.parse(localStorage.getItem('phonetorSettings'));
  const linkFromLocalStorage = storedSettings?.linkPrefix || 'https://phonetor.com'; // Default if no link in localStorage
  const finalMessage = `${message}${linkEnabled ? ` ${linkFromLocalStorage}` : ''}`;

  // Retrieve the API details from localStorage
  const apiDetails = storedSettings?.smsAPIDetails;

  if (!apiDetails || !apiDetails.provider) {
    console.error('Invalid SMS API provider:', apiDetails);
    throw new Error('Invalid SMS API provider.');
  }

  // Retrieve the provider's configuration from smsProvidersConfig.js
  const providerConfig = smsProviders[apiDetails.provider];

  if (!providerConfig) {
    console.error('Invalid provider in config:', apiDetails.provider);
    throw new Error('Invalid SMS API provider configuration.');
  }

  for (const contact of contacts) {
    let statusMessage;
    let statusIcon;

    try {
      if (useSmtp) {
        // Validate if the contact is in an SMTP format
        if (!contact.includes('@')) {
          throw new Error(`Invalid contact format for SMTP. Expected email-like format, got ${contact}`);
        }

        // Log details before sending via SMTP

        // Ensure SMTP details are available
        if (!smtpDetails) {
          throw new Error('SMTP details are missing');
        }

        const emailFormatContact = `${contact}`; // Adjust contact format based on the file
        const smtpResponse = await sendSmtpSms(smtpDetails, emailFormatContact, finalMessage);


        if (smtpResponse.success) {
          statusMessage = `Email2SMS <--->to<---> ${emailFormatContact} Sent`;
          statusIcon = <CheckIcon className="text-green-500 h-5 w-5 inline-block" />;
        } else {
          statusMessage = `Email2SMS <--->to<---> ${emailFormatContact}: ${smtpResponse.error || 'Failed'}`;
          statusIcon = <XIcon className="text-red-500 h-5 w-5 inline-block" />;
        }
      } else {
        // Validate if the contact is in a phone number format (not email-like)
        if (contact.includes('@')) {
          throw new Error(`Invalid contact format for API. Expected phone number format, got ${contact}`);
        }

        // Use API provider to format contact if needed
        const formattedContact = providerConfig.formatPhoneNumber ? providerConfig.formatPhoneNumber(contact) : contact;

        const apiResponse = await sendApiSms(apiDetails, formattedContact, finalMessage);


        if (apiResponse.success) {
          statusMessage = `SMS <--->to<---> ${formattedContact} Sent`;
          statusIcon = <CheckIcon className="text-green-500 h-5 w-5 inline-block" />;
        } else {
          statusMessage = `SMS <--->to<---> ${formattedContact}: ${apiResponse.error || 'Failed'}`;
          statusIcon = <XIcon className="text-red-500 h-5 w-5 inline-block" />;
        }
      }

      // Append status with icon to the feed
      updatedFeed.push({
        contact,
        status: (
          <>
            {statusIcon} {statusMessage}
          </>
        ),
      });

    } catch (error) {
      // Catch errors and update the feed with error icon
      console.error('Error during message sending:', error); // Log the error
      updatedFeed.push({
        contact,
        status: (
          <>
            <XIcon className="text-red-500 h-5 w-5 inline-block" /> Error sending to {contact}: {error.message}
          </>
        ),
      });
    }
  }

  return updatedFeed; // Return the updated progress feed
};
