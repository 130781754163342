import React, { useState, useEffect } from 'react';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { PaperClipIcon, ChatIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { getAuth } from 'firebase/auth';

const TelegramSettings = () => {
  const [settings, setSettings] = useState({
    botToken: '',
    chatId: ''
  });
  const [testStatus, setTestStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [usedByUid, setUsedByUid] = useState(null);

  const auth = getAuth();

  const fetchUsedByUid = async () => {
    try {
      const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(accessKeyQuery);

      if (!querySnapshot.empty) {
        const accessKeyData = querySnapshot.docs[0].data();
        setUsedByUid(accessKeyData.usedBy);
      }
    } catch (error) {
      console.error("Error fetching usedBy UID:", error);
    }
  };

  useEffect(() => {
    fetchUsedByUid();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      if (!usedByUid) return;

      try {
        const settingsRef = doc(db, 'telegramSettings', usedByUid);
        const settingsSnapshot = await getDoc(settingsRef);

        if (settingsSnapshot.exists()) {
          setSettings(settingsSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching settings: ", error);
      }
    };

    fetchSettings();
  }, [usedByUid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (!usedByUid) return;

    try {
      await setDoc(doc(db, 'telegramSettings', usedByUid), settings);
      setAlertMessage('Settings saved successfully!');
    } catch (error) {
      console.error("Error saving settings: ", error);
      setAlertMessage('Error saving settings. Please try again.');
    }
  };

  const testTelegramConnection = async () => {
    if (!settings.botToken || !settings.chatId) {
      setAlertMessage('Please provide both Bot Token and Chat ID.');
      return;
    }

    setTestStatus('Testing connection...');

    const testMessage = {
      chat_id: settings.chatId,
      text: 'This is a test message from your bot!',
    };

    try {
      const response = await fetch(`https://api.telegram.org/bot${settings.botToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(testMessage),
      });

      if (response.ok) {
        setTestStatus('Test message sent successfully!');
      } else {
        setTestStatus('Failed to send test message. Check your settings.');
      }
    } catch (error) {
      setTestStatus('Error testing connection. Please check the console for more details.');
      console.error("Error testing Telegram connection: ", error);
    }
  };

  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg space-y-4">
      <h2 className="text-2xl font-semibold flex items-center space-x-2">
        <ChatIcon className="h-6 w-6 text-blue-500" />
        <span>Telegram Bot Settings</span>
      </h2>
      
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <PaperClipIcon className="h-5 w-5 text-gray-400" />
          <input
            type="text"
            name="botToken"
            value={settings.botToken}
            onChange={handleInputChange}
            placeholder="Bot Token"
            className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
          />
        </div>
        
        <div className="flex items-center space-x-2">
          <PaperClipIcon className="h-5 w-5 text-gray-400" />
          <input
            type="text"
            name="chatId"
            value={settings.chatId}
            onChange={handleInputChange}
            placeholder="Chat ID"
            className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
          />
        </div>
      </div>

      {alertMessage && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {alertMessage}
        </motion.div>
      )}

      <div className="flex justify-between items-center">
        <motion.button
          onClick={handleSave}
          className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Save Settings
        </motion.button>
        
        <motion.button
          onClick={testTelegramConnection}
          className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Test Connection
        </motion.button>
      </div>

      {testStatus && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {testStatus}
        </motion.div>
      )}
    </div>
  );
};

export default TelegramSettings;
