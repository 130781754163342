import React, { useState, useEffect } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useAuth } from './authContext';
import PasswordResetModal from './PasswordResetModal';
import GetAccessModal from './GetAccessModal';
import AuthKeyModal from './AuthKeyModal';

const LoginForm = () => {
  const { login, authKeyRequired, authKeyValid } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showGetAccessModal, setShowGetAccessModal] = useState(false);
  const [showAuthKeyModal, setShowAuthKeyModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Animation flag

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError('Please fill in both fields');
      return;
    }

    setIsAnimating(true);  // Start button animation

    // Simulate a short delay to show animation before submitting
    setTimeout(async () => {
      try {
        await login(email, password);
        setError(null);
        if (authKeyRequired) {
          setShowAuthKeyModal(true); // Show the Auth Key modal after successful login
        }
      } catch (err) {
        setError('Failed to log in. Please check your credentials and try again.');
      } finally {
        setIsAnimating(false); // Stop animation
      }
    }, 300); // 300ms delay to ensure animation plays
  };

  useEffect(() => {
    if (authKeyValid) {
      setShowAuthKeyModal(false); // Close Auth Key modal if key is valid
    }
  }, [authKeyValid]);

  return (
    <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
      <h2 className="text-2xl font-semibold text-gray-100 text-center mb-6">Login to Your Account</h2>
      <form onSubmit={handleLogin}>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block text-gray-400">Email</label>
          <input
            type="email"
            className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4 relative">
          <label className="block text-gray-400">Password</label>
          <div className="relative">
            <input
              type={passwordVisible ? 'text' : 'password'}
              className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-300 focus:outline-none"
            >
              {passwordVisible ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <motion.button 
            type="submit" 
            className="px-6 py-2 bg-blue-600 text-white rounded-lg focus:outline-none focus:bg-blue-700"
            whileHover={{ scale: 1.05, backgroundColor: "#1d4ed8" }} // Scale up and slightly darken on hover
            whileTap={{ scale: 0.95, backgroundColor: "#1e3a8a" }}   // Scale down and darken on click
            initial={{ opacity: 0, y: -20 }}                         // Fade in and slide up on load
            animate={{ opacity: 1, y: 0 }}                           // Final state: fully visible and in position
            transition={{ duration: 0.3 }}                           // Smooth transition for fade and movement
            disabled={isAnimating}                                   // Disable button while animating
          >
            {isAnimating ? 'Logging in...' : 'Login'}                {/* Show "Logging in..." during animation */}
          </motion.button>
          <button 
            type="button"
            className="text-sm text-blue-400 hover:underline focus:outline-none"
            onClick={() => setShowPasswordResetModal(true)}
          >
            Forgot Password?
          </button>
          <button 
            type="button"
            className="text-sm text-blue-400 hover:underline focus:outline-none ml-4"
            onClick={() => setShowGetAccessModal(true)}
          >
            Get Access
          </button>
        </div>
      </form>

      {/* Password Reset Modal */}
      {showPasswordResetModal && <PasswordResetModal onClose={() => setShowPasswordResetModal(false)} />}
      {/* Get Access Modal */}
      {showGetAccessModal && <GetAccessModal onClose={() => setShowGetAccessModal(false)} />}
      {/* Auth Key Modal */}
      {showAuthKeyModal && authKeyRequired && <AuthKeyModal onClose={() => setShowAuthKeyModal(false)} />}
    </div>
  );
};

export default LoginForm;
