// src/components/auth/GetAccessModal.js

import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { FaBitcoin, FaDollarSign } from 'react-icons/fa';
import { MdClose, MdContentCopy } from 'react-icons/md';
import { pricingPlans } from '../../config/pricing';
import { QRCodeCanvas } from 'qrcode.react';

const GetAccessModal = ({ onClose }) => {
  const [paymentMethod, setPaymentMethod] = useState('bitcoin');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [transactionHash, setTransactionHash] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handlePlanSelection = (e) => {
    setSelectedPlan(e.target.value);
  };

  const handleScreenshotUpload = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(getWalletAddress());
    alert('Wallet address copied to clipboard!');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const telegramBotToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const telegramChatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;

    const selectedPlanDetails = pricingPlans.find(plan => plan.name === selectedPlan);

    const text = `
    📝 ---Payment Details---
    \n\n📦 Plan:  ${selectedPlan} (${selectedPlanDetails.plan})
    \n💰 Payment Method:  ${paymentMethod === 'bitcoin' ? '₿ Bitcoin' : '💵 USDT'}
    \n📧 Email:  ${email}
    \n🔗 Transaction Hash:  ${transactionHash}
    \n🚀 SMS Limit: ${selectedPlanDetails.allocation.sms} SMS
    \n🔍 Validation Limit: ${selectedPlanDetails.allocation.validation} Validations
  `;

    try {
      // Send text message with payment details
      await fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: telegramChatId,
          text: text,
          parse_mode: 'Markdown',
        }),
      });

      // Send screenshot if provided
      if (screenshot) {
        const formData = new FormData();
        formData.append('chat_id', telegramChatId);
        formData.append('photo', screenshot);
        formData.append('caption', `📷 **Payment Screenshot**`);

        await fetch(`https://api.telegram.org/bot${telegramBotToken}/sendPhoto`, {
          method: 'POST',
          body: formData,
        });
      }

      setStatus('Payment details sent successfully!');
      setShowConfirmationModal(true);
    } catch (error) {
      setStatus('Failed to send payment details. Please try again.');
    }
  };

  const getWalletAddress = () => {
    if (paymentMethod === 'bitcoin') {
      return process.env.REACT_APP_BITCOIN_WALLET;
    } else if (paymentMethod === 'usdt') {
      return process.env.REACT_APP_USDT_WALLET;
    }
  };

  const handleClickOutside = useCallback((e) => {
    if (e.target.id === 'modal-overlay') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div id="modal-overlay" className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-start overflow-y-auto">
      <motion.div 
        className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] mt-12 overflow-y-auto"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center sticky top-0 bg-gray-800 p-4">
          <h2 className="text-xl font-semibold text-gray-100">Get Access</h2>
          <button onClick={onClose} className="text-gray-100 hover:text-gray-400">
            <MdClose className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Select Your Plan</label>
            <select 
              className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
              onChange={handlePlanSelection}
              value={selectedPlan}
            >
              <option value="" disabled>Select a Plan</option>
              {pricingPlans.map(plan => (
                <option key={plan.name} value={plan.name}>
                  {plan.name} ({plan.plan})
                </option>
              ))}
            </select>
          </div>

          {selectedPlan && (
            <div className="mb-4 bg-gray-700 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-100">{selectedPlan} ({pricingPlans.find(plan => plan.name === selectedPlan).plan})</h3>
              <p className="text-gray-300">
                {pricingPlans.find(plan => plan.name === selectedPlan).description}
              </p>
              <ul className="mt-2 text-gray-200">
                {Object.entries(pricingPlans.find(plan => plan.name === selectedPlan).prices).map(([duration, price]) => (
                  <li key={duration}>
                    <span className="font-bold">{duration}</span>: {price}
                  </li>
                ))}
                <li className="font-bold mt-2">SMS Limit: {pricingPlans.find(plan => plan.name === selectedPlan).allocation.sms}</li>
                <li className="font-bold">Validation Limit: {pricingPlans.find(plan => plan.name === selectedPlan).allocation.validation}</li>
              </ul>
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Choose Payment Method  (TRC20 for USDT)</label>
            <div className="flex space-x-4">
              <motion.div 
                className={`cursor-pointer p-4 rounded-lg ${paymentMethod === 'bitcoin' ? 'bg-yellow-500' : 'bg-gray-700'}`}
                onClick={() => handlePaymentMethodChange('bitcoin')}
                whileHover={{ scale: 1.05 }}
              >
                <FaBitcoin className="w-8 h-8 text-white" />
              </motion.div>
              <motion.div 
                className={`cursor-pointer p-4 rounded-lg ${paymentMethod === 'usdt' ? 'bg-green-500' : 'bg-gray-700'}`}
                onClick={() => handlePaymentMethodChange('usdt')}
                whileHover={{ scale: 1.05 }}
              >
                <FaDollarSign className="w-8 h-8 text-white" />
              </motion.div>
            </div>
          </div>

          {selectedPlan && (
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Wallet Address / QR Code</label>
              <div className="flex justify-between items-center">
                <p className="text-gray-300">{getWalletAddress()}</p>
                <button type="button" onClick={handleCopyAddress} className="text-gray-100 hover:text-gray-400">
                  <MdContentCopy className="w-6 h-6" />
                </button>
              </div>
              <div className="mt-4 flex justify-center">
                <QRCodeCanvas value={getWalletAddress()} size={128} bgColor="#ffffff" fgColor="#000000" />
              </div>
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Transaction Hash</label>
            <input 
              type="text" 
              className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
              value={transactionHash}
              onChange={(e) => setTransactionHash(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Payment Screenshot</label>
            <input 
              type="file" 
              className="w-full text-gray-400"
              onChange={handleScreenshotUpload}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Email Address</label>
            <input 
              type="email" 
              className="w-full px-4 py-2 mt-2 border border-gray-600 rounded-md bg-gray-900 text-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-600"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="flex justify-center">
            <motion.button 
              type="submit" 
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              I Have Paid
            </motion.button>
          </div>

          {status && <p className="mt-4 text-center text-green-500">{status}</p>}
        </form>
      </motion.div>

      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center">
          <motion.div 
            className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full text-center"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold text-gray-100 mb-4">Payment Submitted</h3>
            <p className="text-gray-300">
              Once your payment is confirmed, you will receive your access credentials via the email provided.
            </p>
            <div className="mt-6">
              <motion.button 
                onClick={() => setShowConfirmationModal(false)}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                OK
              </motion.button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default GetAccessModal;
