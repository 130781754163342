import React, { useState, useEffect } from 'react';
import QuestionList from '../../../../config/QuestionList';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { motion } from 'framer-motion';
import ReauthenticateModal from './ReauthenticateModal';

const Questions = () => {
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [question3, setQuestion3] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [locked, setLocked] = useState(true); // Initially, lock the form
  const [usedByUid, setUsedByUid] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showReauthenticateModal, setShowReauthenticateModal] = useState(false);

  const auth = getAuth();

  // Fetch user UID (usedByUid) from Firestore
  const fetchUsedByUid = async () => {
    try {
      const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(accessKeyQuery);

      if (!querySnapshot.empty) {
        const accessKeyData = querySnapshot.docs[0].data();
        setUsedByUid(accessKeyData.usedBy);
      }
    } catch (error) {
      console.error("Error fetching usedBy UID:", error);
    }
  };

  useEffect(() => {
    fetchUsedByUid();
  }, []);

  // Fetch from localStorage or Firestore
  useEffect(() => {
    const fetchSecurityQuestions = async () => {
      // First check localStorage
      const localData = localStorage.getItem('securityQuestions');
      if (localData) {
        const storedQuestions = JSON.parse(localData);
        setQuestion1(storedQuestions.question1 || '');
        setQuestion2(storedQuestions.question2 || '');
        setQuestion3(storedQuestions.question3 || '');
        setAnswer1(storedQuestions.answer1 || '');
        setAnswer2(storedQuestions.answer2 || '');
        setAnswer3(storedQuestions.answer3 || '');
        setLocked(true); // Always lock if data exists
      } else if (usedByUid) {
        try {
          const questionSettingsRef = doc(db, 'questionSettings', usedByUid);
          const questionSettingsSnapshot = await getDoc(questionSettingsRef);
          if (questionSettingsSnapshot.exists()) {
            const storedQuestions = questionSettingsSnapshot.data();
            setQuestion1(storedQuestions.question1 || '');
            setQuestion2(storedQuestions.question2 || '');
            setQuestion3(storedQuestions.question3 || '');
            setAnswer1(storedQuestions.answer1 || '');
            setAnswer2(storedQuestions.answer2 || '');
            setAnswer3(storedQuestions.answer3 || '');
            setLocked(true);
            // Store in localStorage
            localStorage.setItem('securityQuestions', JSON.stringify(storedQuestions));
          }
        } catch (error) {
          console.error("Error fetching security questions:", error);
        }
      }
    };

    fetchSecurityQuestions();
  }, [usedByUid]);

  // Handle Save to Firestore and LocalStorage
  const handleSave = async () => {
    if (!usedByUid) return;

    const questionData = {
      question1,
      answer1,
      question2,
      answer2,
      question3,
      answer3,
    };

    try {
      // Save to Firestore
      await setDoc(doc(db, 'questionSettings', usedByUid), questionData);
      // Save to localStorage
      localStorage.setItem('securityQuestions', JSON.stringify(questionData));
      setAlertMessage('Security questions saved successfully!');
      setLocked(true); // Lock the form after saving
    } catch (error) {
      console.error("Error saving security questions:", error);
      setAlertMessage('Error saving security questions. Please try again.');
    }
  };

  // Handle Update (Reauthenticate user before allowing updates)
  const handleUpdate = () => {
    setIsUpdating(true);
    setShowReauthenticateModal(true); // Trigger reauthentication
  };

  const handleReauthenticationSuccess = () => {
    setLocked(false); // Unlock the form after successful reauthentication
    setShowReauthenticateModal(false);
    setIsUpdating(false);
  };

  // Filter available questions to avoid duplicates
  const getAvailableQuestions = (selectedQuestion) => {
    return QuestionList.filter(
      (q) => q !== question1 && q !== question2 && q !== question3 && q !== selectedQuestion
    );
  };

  return (
    <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Security Questions</h2>

      {locked ? (
        <p className="text-green-400">Security Questions Turned On</p>
      ) : (
        <>
          <div className="mb-6">
            <label className="block text-gray-400">Question 1</label>
            <select
              value={question1}
              onChange={(e) => setQuestion1(e.target.value)}
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            >
              <option value="">Select Question 1</option>
              {getAvailableQuestions(question1).map((question) => (
                <option key={question} value={question}>
                  {question}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={answer1}
              onChange={(e) => setAnswer1(e.target.value)}
              placeholder="Answer"
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-400">Question 2</label>
            <select
              value={question2}
              onChange={(e) => setQuestion2(e.target.value)}
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            >
              <option value="">Select Question 2</option>
              {getAvailableQuestions(question2).map((question) => (
                <option key={question} value={question}>
                  {question}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={answer2}
              onChange={(e) => setAnswer2(e.target.value)}
              placeholder="Answer"
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-400">Question 3</label>
            <select
              value={question3}
              onChange={(e) => setQuestion3(e.target.value)}
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            >
              <option value="">Select Question 3</option>
              {getAvailableQuestions(question3).map((question) => (
                <option key={question} value={question}>
                  {question}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={answer3}
              onChange={(e) => setAnswer3(e.target.value)}
              placeholder="Answer"
              className="w-full px-4 py-2 mt-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              disabled={locked}
            />
          </div>
        </>
      )}

      {locked ? (
        <motion.button
          onClick={handleUpdate}
          className="w-full px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
        >
          Update Security Questions
        </motion.button>
      ) : (
        <motion.button
          onClick={handleSave}
          className="w-full px-4 py-2 text-white bg-green-600 hover:bg-green-700 rounded-lg"
        >
          Save Security Questions
        </motion.button>
      )}

      {alertMessage && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {alertMessage}
        </motion.div>
      )}

      {isUpdating && showReauthenticateModal && (
        <ReauthenticateModal
          onClose={() => setShowReauthenticateModal(false)}
          onSuccess={handleReauthenticationSuccess}
        />
      )}
    </div>
  );
};

export default Questions;
