import React, { useState } from 'react';
import PhonetorUI from './PhonetorUI';
import { usePhonetorState } from './PhonetorFunctions';
import { checkSMSCount, deductSMSCredits } from '../../../config/smsRules'; // Updated imports for SMS rules
import { sendMessages } from './PhonetorSender';

const Phonetor = () => {
  const {
    contacts,
    message,
    linkEnabled,
    useSmtp,
    selectedSMSAPI,  // Selected SMS API
    smtpDetails,      // SMTP Details
    setLinkEnabled,
    setUseSmtp,
    handleFileChange,
    handleMessageChange,
    usedBy,  // For SMS credit deduction
  } = usePhonetorState();  // State management from PhonetorFunctions

  const [progressFeed, setProgressFeed] = useState([]);
  const [sendingProgress, setSendingProgress] = useState(0); // New state for progress bar
  const [saveMessage, setSaveMessage] = useState('');

  const handleSendMessages = async () => {
    if (contacts.length === 0 || message.trim() === '') {
      setSaveMessage('Please upload contacts and provide a message.');
      return;
    }

    const numberOfContacts = contacts.length;

    // Check if the user has enough SMS credits
    const hasEnoughCredits = await checkSMSCount(usedBy, numberOfContacts);

    if (!hasEnoughCredits) {
      setSaveMessage('Insufficient SMS credits.');
      return;
    }

    let progress = 0;
    setSendingProgress(progress); // Reset progress

    // Proceed with sending messages if enough credits
    const updatedFeed = [];
    let allSuccess = true; // Track if all messages were sent successfully

    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];

      // Ensure the contact format is correct based on the method (API or SMTP)
      if (useSmtp && !contact.includes('@')) {
        updatedFeed.push({
          contact,
          status: `Error: ${contact} is not a valid email for SMTP. Switch to API`,
        });
        allSuccess = false;
        continue; // Skip to the next contact
      } else if (!useSmtp && contact.includes('@')) {
        updatedFeed.push({
          contact,
          status: `Error: ${contact} is not a valid phone number for API. Switch to SMTP`,
        });
        allSuccess = false;
        continue; // Skip to the next contact
      }

      // Send individual message and add it to the feed
      const result = await sendMessages([contact], useSmtp, selectedSMSAPI, smtpDetails, message, linkEnabled);
      updatedFeed.push(...result);

      // Check if there's any failure in the sending
      if (result.some((res) => typeof res.status === 'string' && res.status.includes('Failed'))) {
        allSuccess = false;
      }

      // Update progress percentage
      progress = Math.round(((i + 1) / contacts.length) * 100);
      setSendingProgress(progress);
    }

    setProgressFeed(updatedFeed);

    if (allSuccess) {
      // Deduct SMS credits only if all messages were sent successfully
      await deductSMSCredits(usedBy, numberOfContacts);
      setSaveMessage('Messages sent successfully!');
    } else {
      setSaveMessage('Some messages failed to send.');
    }
  };

  return (
    <div>
      <PhonetorUI
        useSmtp={useSmtp}
        linkEnabled={linkEnabled}
        contacts={contacts}
        message={message}
        handleFileChange={handleFileChange}
        handleMessageChange={handleMessageChange}
        handleToggleSMTP={setUseSmtp}
        handleToggleLink={setLinkEnabled}
        handleSendMessages={handleSendMessages}
        progressFeed={progressFeed} // Pass the progressFeed to PhonetorUI
        sendingProgress={sendingProgress} // Pass sendingProgress to PhonetorUI
      />
      {saveMessage && (
        <div className={saveMessage.includes('failed') ? 'text-red-500 mt-4' : 'text-green-500 mt-4'}>
          {saveMessage}
        </div>
      )}
    </div>
  );
};

export default Phonetor;
