// src/components/home/Footer.js

import React from 'react';
import { FaTwitter, FaTelegramPlane, FaSms, FaEnvelope, FaPhone, FaGlobe } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-200 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          
          {/* About Section */}
          <div className="mb-8 md:mb-0 md:w-1/4">
            <h3 className="text-lg font-semibold text-white">About SMS Portal</h3>
            <p className="text-gray-400 mt-2">
              SMS Portal is a leading service provider offering fast, secure, and reliable SMS communication solutions for businesses and individuals.
            </p>
          </div>

          {/* Sitemap Links */}
          <div className="flex flex-col md:flex-row md:space-x-16">
            {/* Social Media Section */}
            <div className="mb-8 md:mb-0">
              <h4 className="text-lg font-semibold text-white">Follow Us</h4>
              <div className="flex space-x-4 mt-2">
                <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300">
                  <FaTwitter className="h-6 w-6 text-blue-500" />
                </a>
                <a href="https://t.me/yourprofile" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300">
                  <FaTelegramPlane className="h-6 w-6 text-blue-400" />
                </a>
              </div>
            </div>

            {/* Services Section */}
            <div className="mb-8 md:mb-0">
              <h4 className="text-lg font-semibold text-white">Services</h4>
              <ul className="mt-2 space-y-2">
                <li className="flex items-center space-x-2">
                  <FaSms className="text-blue-500" />
                  <a href="/services/bulk-sms" className="text-gray-400 hover:text-white">Bulk SMS</a>
                </li>
                <li className="flex items-center space-x-2">
                  <FaSms className="text-green-500" />
                  <a href="/services/sms-api" className="text-gray-400 hover:text-white">SMS API</a>
                </li>
                <li className="flex items-center space-x-2">
                  <FaSms className="text-yellow-500" />
                  <a href="/services/phone-validation" className="text-gray-400 hover:text-white">Phone Validation</a>
                </li>
              </ul>
            </div>

            {/* Contact Section */}
            <div className="mb-8 md:mb-0">
              <h4 className="text-lg font-semibold text-white">Contact Us</h4>
              <ul className="mt-2 space-y-2">
                <li className="flex items-center space-x-2">
                  <FaEnvelope className="text-red-500" />
                  <a href="mailto:info@smsportal.com" className="text-gray-400 hover:text-white">info@smsportal.com</a>
                </li>
                <li className="flex items-center space-x-2">
                  <FaPhone className="text-green-500" />
                  <a href="tel:+1234567890" className="text-gray-400 hover:text-white">+1 234 567 890</a>
                </li>
                <li className="flex items-center space-x-2">
                  <FaGlobe className="text-blue-500" />
                  <a href="/contact" className="text-gray-400 hover:text-white">Contact Form</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
