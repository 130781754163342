// src/components/home/SubHero.js

import React from 'react';
import { motion } from 'framer-motion';
import { LightningBoltIcon, ShieldCheckIcon, DeviceMobileIcon, PhoneIcon } from '@heroicons/react/outline';

const features = [
  {
    icon: <LightningBoltIcon className="h-10 w-10 text-blue-500" />,
    title: "Fast Delivery",
    description: "Instant SMS delivery to your audience."
  },
  {
    icon: <ShieldCheckIcon className="h-10 w-10 text-green-500" />,
    title: "Secure",
    description: "Your data is protected with top-notch security."
  },
  {
    icon: <DeviceMobileIcon className="h-10 w-10 text-indigo-500" />,
    title: "Mobile Friendly",
    description: "Manage your campaigns from any device."
  },
  {
    icon: <PhoneIcon className="h-10 w-10 text-yellow-500" />,
    title: "Phone Validation",
    description: "Ensure accurate delivery with real-time phone number validation."
  }
];

const SubHero = () => {
  return (
    <div className="bg-gray-900 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold text-gray-100 mb-8">Why Choose SMS Portal?</h2>
        <div className="flex justify-center items-center space-x-6 overflow-x-auto">
          {features.map((feature, index) => (
            <div key={index} className="flex-shrink-0 w-56 flex flex-col items-center justify-center px-4">
              <motion.div
                whileHover={{ scale: 1.2, rotate: 10 }}
                whileTap={{ scale: 0.9, rotate: -10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                {feature.icon}
              </motion.div>
              <h3 className="mt-4 text-xl font-semibold text-gray-100">{feature.title}</h3>
              <p className="mt-2 text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubHero;
