// src/components/users/validator/useFetchApiKeys.js
import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../../config/firebaseConfig'; // Adjust the path as necessary

const useFetchApiKeys = () => {
    const [apiKeys, setApiKeys] = useState([]);

    useEffect(() => {
        const fetchApiKeys = async (user) => {
            try {
                const docRef = doc(db, 'validatorSettings', user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.apiKeys && data.apiKeys.length > 0) {
                        setApiKeys(data.apiKeys);
                    } else {
                        console.error("No API keys found in validatorSettings.");
                    }
                } else {
                    console.error("No settings document found for the user.");
                }
            } catch (error) {
                console.error("Error fetching API keys from Firestore:", error);
            }
        };

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchApiKeys(user);
            }
        });

        return () => unsubscribe();
    }, []);

    return apiKeys;
};

export default useFetchApiKeys;
