// src/pages/Home.js

import React from 'react';
import Hero from '../components/home/Hero';
import SubHero from '../components/home/SubHero';
import Footer from '../components/home/Footer';
import Header from '../components/navs/Header';

const Home = () => {
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <Header />
      <Hero />
      <SubHero />
      <Footer />
    </div>
  );
};

export default Home;
