import React, { useState } from 'react';
import SMTPSettings from '../../settings/SMTPSettings/SMTPSettings';
import SMSAPISettings from '../../settings/SMSAPISettings/SMSAPISettings';
import ValidatorSettings from '../../settings/ValidatorSettings/ValidatorSettings';
import TelegramSettings from '../../settings/TelegramSettings/TelegramSettings';
import { XIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import MobileSettingsNav from './MobileSettingsNav'; // Correct import path

const MobileSettingsModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('smtp');

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'smtp':
        return <SMTPSettings />;
      case 'smsApi':
        return <SMSAPISettings />;
      case 'validator':
        return <ValidatorSettings />;
      case 'telegram':
        return <TelegramSettings />;
      default:
        return <SMTPSettings />;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="bg-gray-800 text-white p-4 rounded-lg shadow-lg w-full max-w-md h-full overflow-y-auto"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Settings</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>
        
        {/* Integrate MobileSettingsNav */}
        <MobileSettingsNav activeTab={activeTab} setActiveTab={setActiveTab} />

        <div className="mt-4 overflow-y-auto h-full smooth-scroll">
          {renderActiveTab()}
        </div>
      </motion.div>
    </div>
  );
};

export default MobileSettingsModal;
