import React, { useState, useEffect } from 'react';
import { InformationCircleIcon, ClockIcon, BellIcon } from '@heroicons/react/outline';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import NotificationModal from './NotificationModal';
import { useAuth } from '../../../auth/authContext';

const Subheader = () => {
  const { currentUser } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchExpiryDate = async () => {
      if (currentUser) {
        const keyDocRef = query(collection(db, 'AccessKeys'), where('usedBy', '==', currentUser.uid));
        const keySnapshot = await getDocs(keyDocRef);
        
        keySnapshot.forEach((doc) => {
          const data = doc.data();
          const expiresAt = data.expiresAt.toDate(); // Convert Firestore timestamp to JS Date

          const intervalId = setInterval(() => {
            const now = new Date();
            const timeDiff = expiresAt - now;

            if (timeDiff <= 0) {
              clearInterval(intervalId);
              setTimeRemaining('Expired');
            } else {
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
              setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            }
          }, 1000);

          return () => clearInterval(intervalId); // Clean up the interval on unmount
        });
      }
    };

    const fetchNotifications = async () => {
      const notificationsRef = collection(db, 'Notifications');
      const notificationsSnapshot = await getDocs(notificationsRef);
      
      const fetchedNotifications = [];
      notificationsSnapshot.forEach((doc) => {
        fetchedNotifications.push(doc.data());
      });

      setNotifications(fetchedNotifications);
    };

    fetchExpiryDate();
    fetchNotifications();
  }, [currentUser]);

  return (
    <div className="bg-gray-700 text-white p-4 flex items-center justify-between shadow-md mt-16 rounded-lg space-x-4">
      <div className="flex items-center space-x-4">
        <ClockIcon className="h-6 w-6 text-yellow-400" />
        <span className="text-lg font-semibold">Key Expires In:</span>
        <span className="text-lg font-semibold text-blue-400">{timeRemaining}</span>
      </div>
      <button 
        onClick={() => setShowModal(true)} 
        className="flex items-center space-x-2 hover:text-gray-300 transition duration-300"
      >
        <BellIcon className="h-6 w-6 text-yellow-400" />
        <span className="text-lg font-semibold">New Notifications</span>
      </button>
      {showModal && <NotificationModal notifications={notifications} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Subheader;
