import React from 'react';
import {
  PhoneIcon,
  GlobeAltIcon,
  OfficeBuildingIcon,
  MailIcon,
  CheckCircleIcon,
  XIcon,
  FlagIcon,
} from '@heroicons/react/solid';

const MobilePhoneTable = ({ filteredNumbers }) => {
  return (
    <div className="mt-4">
      {filteredNumbers.map((phone, index) => (
        <div
          key={index}
          className={`bg-white dark:bg-gray-800 p-4 mb-4 rounded-lg shadow-lg ${
            phone.isValid ? 'border-l-4 border-green-500' : 'border-l-4 border-red-500'
          }`}
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center space-x-2 text-gray-800 dark:text-gray-300">
              <PhoneIcon className="h-5 w-5 text-blue-500" />
              <span className="font-semibold">{phone.number}</span>
            </div>
            <div className="flex items-center">
              {phone.isValid ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-400 mb-2">
            <div className="flex items-center space-x-2">
              <GlobeAltIcon className="h-4 w-4" />
              <span>{phone.country || 'N/A'}</span>
            </div>
            <div className="flex items-center space-x-2">
              <OfficeBuildingIcon className="h-4 w-4" />
              <span>{phone.carrier || 'N/A'}</span>
            </div>
            <div className="flex items-center space-x-2">
              <MailIcon className="h-4 w-4" />
              <span>{phone.carrierEmail.replace(/^\+1/, '') || 'N/A'}</span>
            </div>
          </div>
          <div className="text-sm text-red-600 dark:text-red-400">
            {phone.flags && phone.flags.length > 0 ? (
              phone.flags.map((flag, i) => (
                <div key={i} className="mb-1">
                  <div className="flex items-center space-x-2">
                    <FlagIcon className="h-4 w-4" />
                    <span className="font-semibold">Note:</span>
                    <span>{flag.note}</span>
                  </div>
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    Industry: {flag.industry}
                  </div>
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    Date: {new Date(flag.date * 1000).toLocaleDateString()}
                  </div>
                </div>
              ))
            ) : (
              <span>No Flags</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobilePhoneTable;
