import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../config/firebaseConfig'; 
import { doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';

const PhonetorSettings = () => {
  const [smtpAccounts, setSmtpAccounts] = useState([]);
  const [selectedSMTP, setSelectedSMTP] = useState('');
  const [smsAPIs, setSmsAPIs] = useState([]);
  const [selectedSMSAPI, setSelectedSMSAPI] = useState('');
  const [linkPrefix, setLinkPrefix] = useState('');
  const [usedBy, setUsedBy] = useState(null);
  const [saveMessage, setSaveMessage] = useState('');
  const [loadedSettings, setLoadedSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', auth.currentUser.uid));
        const accessKeySnapshot = await getDocs(accessKeyQuery);

        if (!accessKeySnapshot.empty) {
          const accessKeyDoc = accessKeySnapshot.docs[0];
          const uid = accessKeyDoc.data().usedBy;
          setUsedBy(uid);
          await fetchSMTPSettings(uid);
          await fetchSMSAPISettings(uid);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();
  }, []);

  // Fetch full SMTP details from Firebase
  const fetchSMTPSettings = async (uid) => {
    try {
      const docRef = doc(db, 'smtpSettings', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const smtpData = docSnap.data().smtp || [];
        setSmtpAccounts(smtpData);

        // Set default selected SMTP account (first account)
        if (!selectedSMTP) {
          setSelectedSMTP(0); // Default to the first index in the array
        }
      }
    } catch (error) {
      console.error('Error fetching SMTP settings:', error);
    }
  };

  // Fetch full SMS API details from Firebase
  const fetchSMSAPISettings = async (uid) => {
    try {
      const smsAPICollection = collection(db, 'smsAPISettings');
      const q = query(smsAPICollection, where('usedBy', '==', uid));
      const querySnapshot = await getDocs(q);

      const apis = [];
      querySnapshot.forEach((doc) => {
        const apiData = doc.data();
        apis.push({
          id: doc.id,
          provider: apiData.provider,
          apiKey: apiData.apiKey,
          apiSecret: apiData.apiSecret,
          phoneNumber: apiData.phoneNumber,
          senderId: apiData.senderId, // Add dynamic field support
          domain: apiData.domain, // Add dynamic field support for specific providers
        });
      });
      setSmsAPIs(apis);

      if (!selectedSMSAPI) {
        setSelectedSMSAPI(apis[0]?.provider || '');
      }
    } catch (error) {
      console.error('Error fetching SMS API settings:', error);
    }
  };

  // Save full SMTP and SMS API details to localStorage
  const handleSave = () => {
    const selectedSMTPDetails = smtpAccounts[selectedSMTP]; // Use index to find selected SMTP account
    const selectedSMSAPIDetails = smsAPIs.find((api) => api.provider === selectedSMSAPI);

    const selectedSettings = {
      smtpDetails: selectedSMTPDetails || null, // Save full SMTP details
      smsAPIDetails: selectedSMSAPIDetails || null, // Save full SMS API details
      linkPrefix: linkPrefix || '',
    };

    // Save the full settings to localStorage
    localStorage.setItem('phonetorSettings', JSON.stringify(selectedSettings));

    setSaveMessage('Settings saved successfully!');
    
    setTimeout(() => {
      setSaveMessage('');
    }, 3000);
  };

  // Load settings from localStorage
  const handleLoadSettings = () => {
    const storedSettings = JSON.parse(localStorage.getItem('phonetorSettings'));
    if (storedSettings) {
      setLoadedSettings(storedSettings);
    } else {
      setSaveMessage('No settings found in localStorage.');
      setTimeout(() => {
        setSaveMessage('');
      }, 3000);
    }
  };

  // Interpret the command for display
  const interpretCommand = (command) => {
    if (!command) {
      return {
        smtpStatus: "No command settings found.",
        linkStatus: "No command settings found."
      };
    }
    let smtpStatus = command.useSMTP === '#1' ? 'Use SMTP' : 'Use API';
    let linkStatus = command.linkOn === '#1' ? 'Link Included' : 'Link Not Included';
    return {
      smtpStatus,
      linkStatus,
    };
  };

  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg space-y-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <h2 className="text-2xl font-semibold">Phonetor Settings</h2>

        <div className="space-y-4">
          {/* SMTP Dropdown */}
          <div>
            <label className="block text-gray-400">Select SMTP Account</label>
            <select
              value={selectedSMTP}
              onChange={(e) => setSelectedSMTP(parseInt(e.target.value))} // Use index as value
              className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
            >
              <option value="">Select SMTP Account</option>
              {smtpAccounts.map((smtp, index) => (
                <option key={index} value={index}> {/* Use index as value */}
                  {smtp.host} - {smtp.user} {/* Display limited details */}
                </option>
              ))}
            </select>
          </div>

          {/* SMS API Dropdown */}
          <div>
            <label className="block text-gray-400">Select SMS API Provider</label>
            <select
              value={selectedSMSAPI}
              onChange={(e) => setSelectedSMSAPI(e.target.value)}
              className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
            >
              <option value="">Select SMS API Provider</option>
              {smsAPIs.map((api, index) => (
                <option key={index} value={api.provider}>
                  {api.provider} - {api.apiKey} {/* Display limited details */}
                </option>
              ))}
            </select>
          </div>

          {/* Link Prefix Input */}
          <div>
            <label className="block text-gray-400">Link Prefix(Shortened)</label>
            <input
              type="text"
              name="linkPrefix"
              value={linkPrefix}
              onChange={(e) => setLinkPrefix(e.target.value)}
              className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
              placeholder="Enter link prefix"
            />
          </div>
        </div>

        <div className="space-x-4 mt-4">
          <button
            onClick={handleSave}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
          >
            Save Settings
          </button>
          <button
            onClick={handleLoadSettings}
            className="bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-4 rounded-lg"
          >
            Load Current Settings
          </button>
        </div>

        {/* Display the save message */}
        {saveMessage && (
          <div className="mt-4 text-green-500">
            {saveMessage}
          </div>
        )}
      </div>

      {/* Right Section */}
      <div>
        <h2 className="text-2xl font-semibold">Loaded Settings</h2>
        {loadedSettings && (
          <div className="space-y-4 mt-4">
            <div>
              <strong>Selected SMTP:</strong>
              <ul>
                <li><strong>Host:</strong> {loadedSettings.smtpDetails?.host || 'N/A'}</li>
                <li><strong>From:</strong> {loadedSettings.smtpDetails?.from || 'N/A'}</li>
                <li><strong>User:</strong> {loadedSettings.smtpDetails?.user || 'N/A'}</li>
                <li><strong>Password:</strong> {loadedSettings.smtpDetails?.password || 'N/A'}</li>
                <li><strong>Port:</strong> {loadedSettings.smtpDetails?.port || 'N/A'}</li>
              </ul>
            </div>
            <div>
              <strong>Selected SMS API:</strong>
              <ul>
                {loadedSettings.smsAPIDetails
                  ? Object.entries(loadedSettings.smsAPIDetails).map(([key, value], index) => (
                      key !== 'provider' && key !== 'id' && (
                        <li key={index}><strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}</li>
                      )
                    ))
                  : <li>No SMS API details found.</li>
                }
              </ul>
            </div>
            <div>
              <strong>Link Prefix:</strong> {loadedSettings.linkPrefix || 'None'}
            </div>
            <div>
              <strong>Command:</strong>
              <ul>
                <li><strong>SMTP/API Status:</strong> {interpretCommand(loadedSettings.command).smtpStatus}</li>
                <li><strong>Link Status:</strong> {interpretCommand(loadedSettings.command).linkStatus}</li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhonetorSettings;
