// src/components/home/Hero.js

import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import LoginForm from '../auth/LoginForm';

const Hero = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      x: 0,
      transition: { duration: 0.8 },
    });

    const randomAnimation = () => {
      const rand = Math.random();
      if (rand < 0.33) {
        controls.start({
          scale: [1, 1.05, 1],
          transition: { duration: 0.2 },
        });
      } else if (rand < 0.66) {
        controls.start({
          filter: ['blur(0px)', 'blur(2px)', 'blur(0px)'],
          transition: { duration: 0.5 },
        });
      } else {
        controls.start({
          x: [-2, 2, -2],
          transition: { duration: 0.1, repeat: 3 },
        });
      }
    };

    const interval = setInterval(randomAnimation, 2000);

    return () => clearInterval(interval);
  }, [controls]);

  return (
    <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-black text-gray-100 py-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Titled Header Section */}
        <div className="md:w-1/2 text-center md:text-left px-4">
          <motion.h1 
            className="text-5xl font-extrabold max-w-lg"
            initial={{ opacity: 0, x: -50 }}
            animate={controls}
          >
            Phonetor SMS Portal
          </motion.h1>
          <motion.p 
            className="mt-4 text-lg max-w-lg text-gray-400"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            Manage your SMS campaigns with ease and efficiency.
          </motion.p>
        </div>

        {/* Login Section */}
        <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
          <LoginForm onLoginSuccess={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
