import React, { useState, useEffect } from 'react';
import { db } from '../../../../config/firebaseConfig';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { GlobeIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { smsProviders } from '../../../../config/smsProvidersConfig';
import { getAuth } from 'firebase/auth';

const SMSAPISettings = () => {
  const [selectedProvider, setSelectedProvider] = useState('');
  const [settings, setSettings] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [usedBy, setUsedBy] = useState(null);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUsedByUid = async () => {
      if (currentUser) {
        const accessKeyQuery = query(collection(db, 'AccessKeys'), where('usedBy', '==', currentUser.uid));
        const accessKeySnapshot = await getDocs(accessKeyQuery);

        if (!accessKeySnapshot.empty) {
          const accessKeyDoc = accessKeySnapshot.docs[0];
          setUsedBy(accessKeyDoc.data().usedBy);
          fetchSettings(accessKeyDoc.data().usedBy);
        }
      }
    };

    const fetchSettings = async (usedByUid) => {
      if (selectedProvider) {
        const docRef = doc(db, 'smsAPISettings', `${selectedProvider}_${usedByUid}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSettings(docSnap.data());
        }
      }
    };

    fetchUsedByUid();
  }, [selectedProvider, currentUser]);

  const handleProviderChange = (e) => {
    const provider = e.target.value;
    setSelectedProvider(provider);
    setSettings({});
    setAlertMessage('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (usedBy) {
        try {
            await setDoc(doc(db, 'smsAPISettings', `${selectedProvider}_${usedBy}`), {
                ...settings,
                usedBy,
                provider: selectedProvider  // Save the selected provider
            });
            setAlertMessage('Settings saved successfully!');
        } catch (error) {
            console.error("Error saving settings: ", error);
            setAlertMessage('Error saving settings. Please try again.');
        }
    }
};


  const getHelpText = (fieldName) => {
    switch (fieldName) {
      case 'apiKey':
        return 'The API key provided by your SMS service provider. It’s used to authenticate API requests.';
      case 'apiSecret':
        return 'The secret key provided by your SMS service provider. It works alongside your API key for secure API requests.';
      case 'accountSid':
        return 'The Account SID is a unique identifier for your Twilio account. It’s required for all API requests.';
      case 'authToken':
        return 'The authentication token for your Twilio account. It’s used to authenticate API requests.';
      case 'phoneNumber':
        return 'The phone number provided by your SMS provider, often used as the sender in your SMS campaigns.';
      case 'senderId':
        return 'The Sender ID is a name or number that appears on the recipient’s phone when they receive an SMS.';
      case 'domain':
        return 'The domain registered with your Mailgun account, used for sending and tracking emails.';
      case 'senderEmail':
        return 'The email address from which the SMS messages will be sent, used primarily in services like SendinBlue.';
      default:
        return '';
    }
  };

  return (
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg space-y-4">
      <h2 className="text-2xl font-semibold flex items-center space-x-2">
        <GlobeIcon className="h-6 w-6 text-blue-500" />
        <span>SMS API Settings</span>
      </h2>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <label className="text-gray-400">Select API Provider:</label>
          <select
            value={selectedProvider}
            onChange={handleProviderChange}
            className="bg-gray-900 text-white p-2 rounded-lg border border-gray-600"
          >
            <option value="">Select a provider</option>
            {Object.entries(smsProviders).map(([key, { label }]) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </div>

        {selectedProvider && (
          <div className="space-y-4">
            {smsProviders[selectedProvider].fields.map(({ name, placeholder }) => (
              <div key={name} className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={name}
                    value={settings[name] || ''}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    className="bg-gray-900 text-white p-2 rounded-lg w-full border border-gray-600"
                  />
                </div>
                <p className="text-sm text-gray-400 flex items-center">
                  <InformationCircleIcon className="h-4 w-4 mr-1" />
                  {getHelpText(name)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      {alertMessage && (
        <motion.div
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {alertMessage}
        </motion.div>
      )}

      <motion.button
        onClick={handleSave}
        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Save Settings
      </motion.button>
    </div>
  );
};

export default SMSAPISettings;
