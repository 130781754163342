import React, { useState, useEffect } from 'react';
import { auth, db } from '../../config/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Header from '../users/navs/header/Header';
import MobileHeader from '../users/navs/mobile/MobileHeader';
import Subheader from '../users/navs/subheader/Subheader';
import MobileSubheader from '../users/navs/mobile/MobileSubheader';
import WelcomeBanner from '../users/dashboard/WelcomeBanner/WelcomeBanner';
import QuickAccess from '../users/dashboard/QuickAccess/QuickAccess';
import RecentActivity from '../users/dashboard/RecentActivity/RecentActivity';
import PlanOverview from '../users/dashboard/PlanOverview/PlanOverview';
import SupportSection from '../users/dashboard/SupportSection/SupportSection';

import Phonetor from '../users/phonetor/Phonetor';
import Validator from '../users/validator/Validator';

const Layout = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activities, setActivities] = useState([]);
  const [plan, setPlan] = useState(null);
  const [usage, setUsage] = useState(0);
  const navigate = useNavigate(); // Add navigation hook

  useEffect(() => {
    let isSubscribed = true; // Track subscription status

    const fetchData = async (user) => {
      if (user && isSubscribed) {
        setUserEmail(user.email);

        try {
          const q = query(collection(db, 'AccessKeys'), where('usedBy', '==', user.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              setUserData(data);
              setPlan({
                name: data.category,
                limit: data.allocation.sms,
              });
              setUsage(data.allocation.used || 0);
            });
          } else {
            console.error('No document found for this user!');
          }

          const activitiesRef = collection(db, `Users/${user.uid}/activities`);
          const activitiesQuery = query(activitiesRef, orderBy('timestamp', 'desc'), limit(5));
          const activitiesSnapshot = await getDocs(activitiesQuery);
          const userActivities = activitiesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setActivities(userActivities);
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.code === 'permission-denied') {
            navigate('/'); // Redirect to login if permission is denied
          }
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user);
        const intervalId = setInterval(() => fetchData(user), 3000);

        return () => {
          clearInterval(intervalId);
          isSubscribed = false; // Clean up when component unmounts or user logs out
        };
      } else {
        setUserEmail(null);
        setUserData(null);
        setActivities([]);
        setPlan(null);
        setUsage(0);
        navigate('/'); // Redirect to login if user is not authenticated
      }
    });

    return () => {
      unsubscribe();
      isSubscribed = false;
    };
  }, [navigate]);

  if (!userData || !plan) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="hidden md:block">
        <Header />
        <Subheader />
      </div>

      <main className="pt-16 pb-16 md:pb-0">
        <Routes>
          <Route path="/" element={
            <>
              {userEmail && userData && (
                <WelcomeBanner
                  userName={userEmail}
                  allocation={userData.allocation}
                  category={userData.category}
                  expiresAt={userData.expiresAt.toDate()}
                />
              )}
              <QuickAccess />
              <RecentActivity activities={activities} />
              <PlanOverview plan={plan} usage={usage} />
              <SupportSection />
            </>
          } />
          <Route path="/phonetor" element={<Phonetor />} />
          <Route path="/validator" element={<Validator />} />
        </Routes>
      </main>

      <div className="md:hidden">
        <MobileSubheader />
        <MobileHeader />
      </div>
    </div>
  );
};

export default Layout;
