// src/components/users/navs/subheader/NotificationModal.js

import React from 'react';
import { XIcon } from '@heroicons/react/solid';

const NotificationModal = ({ onClose, notifications }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Notifications</h2>
          <button onClick={onClose} className="focus:outline-none">
            <XIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          </button>
        </div>
        <div className="space-y-4">
          {notifications.length > 0 ? (
            notifications.map((note, index) => (
              <div key={index} className="p-2 bg-gray-700 rounded-md">
                {note}
              </div>
            ))
          ) : (
            <p>No new notifications</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
